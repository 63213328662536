import BadgeTwoTone from "@mui/icons-material/BadgeTwoTone";
import Close from "@mui/icons-material/Close";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  customerURL,
  partnerURL,
  userProfileURL,
} from "../../static/constants/backendRoutes";
import Logout from "../auth/logout";
import UseAuth from "../auth/useAuth";
import JiraTicket from "../dialogs/jiraTicket";
import {
  clearLocalDb,
  errorHandler,
  stringCapitalize,
  stringTruncate,
} from "../utils";
import SideDrawer from "./sideDrawer";

export default function Header(props) {
  const isAuth = useMemo(() => UseAuth("get"));
  const [sideDrawer, setSideDrawer] = useState(true);
  const [profile, setProfile] = useState();
  const [trueProfile, setTrueProfile] = useState(); //if the signed in user is impersonating someone else, their actual profile goes here
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutState, setLogoutState] = useState({
    isOpen: false,
    isImpersonationLogout: false,
  });
  const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
  const [orgAnchorEl, setOrgAnchorEl] = useState(null);

  const {
    pageAccessMemo,
    organizationMemo,
    analysisStepsViewMemo,
    logo,
    accessRights,
  } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();

  // if user is imerpsonating another's account the emails will differ, and if they do, then the user is impersonating
  const isImpersonation =
    Boolean(trueProfile?.email) &&
    trueProfile?.email !== profile?.email && // checks that they are impersonating
    Boolean(profile?.first_name); // checks that the first name exists for display reasons (in the event that the profile is still undefined, waits for it to be defined)

  const profileOpen = Boolean(anchorEl);

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const logOutItemClicked = () => {
    setLogoutState({ isOpen: true, isImpersonationLogout: false });
  };
  const exitImpersonationClicked = () => {
    setLogoutState({ isOpen: true, isImpersonationLogout: true });
  };

  //if user has no projects created, disable all buttons
  const disableAllButtons = pageAccessMemo.pageAccess < 1;

  useEffect(() => {
    function fetchProfile() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      /** fetch all partner-level organizations */
      const partners = fetch(partnerURL, { method: "GET", headers }).then(
        (response) => {
          if (response.ok) return response.json().then(({ data }) => data);
          //do not display error if cause is insufficient permissions
          else if (response.status == 403) return [];
          else {
            errorHandler(response, snackBarElement, "Failed to get partners");
            return [];
          }
        }
      );

      /** fetch all customer-level organizations */
      const customers = fetch(customerURL, { method: "GET", headers }).then(
        (response) => {
          if (response.ok) return response.json().then(({ data }) => data);
          //do not display error if cause is insufficient permissions
          else if (response.status == 403) return [];
          else {
            errorHandler(response, snackBarElement, "Failed to get customers");
            return [];
          }
        }
      );

      //get user profile details
      fetch(userProfileURL, {
        method: "GET",
        headers: { Authorization: `Token ${UseAuth("get")}` },
      })
        .then((response) => {
          if (response.ok)
            response.json().then(({ data }) => {
              UseAuth("set_details", {
                key: data[data.organization_type],
                id: data[`${data.organization_type}_id`],
                user_role: data.user_role_id,
              });
              setProfile(data);

              /** combine partner and customer organization lists, and create a organization lookup table */
              Promise.all([partners, customers])
                .then(([partners, customers]) => {
                  // create initial org lookup state for the current user's organization ID
                  const orgLookup = {
                    [data[`${data.organization_type}_id`]]: "My Organization",
                  };
                  partners
                    .filter((el) => el.is_active)
                    .forEach((el) => (orgLookup[el.id] = el.name));
                  customers
                    .filter((el) => el.is_active)
                    .forEach((el) => (orgLookup[el.id] = el.name));

                  if (Object.values(orgLookup).length)
                    organizationMemo.setLookup(orgLookup);
                })
                .catch((e) => {
                  console.log(e);
                  snackBarElement?.current?.displayToast(
                    "Failed to get organizations",
                    "warning",
                    5000
                  );
                });
            });
          else
            errorHandler(
              response,
              snackBarElement,
              "Failed to get user profile details"
            );
        })
        .catch((error) => console.log(error));

      //if the user is currently impersonating someone
      if (UseAuth("get_id"))
        fetch(userProfileURL, {
          method: "GET",
          headers: { Authorization: `Token ${UseAuth("true_get")}` },
        })
          .then((response) => {
            if (response.ok)
              response.json().then(({ data }) => {
                setTrueProfile(data);
              });
            else
              errorHandler(
                response,
                snackBarElement,
                "Failed to get impersonated user profile details"
              );
          })
          .catch((error) => console.log(error));
    }

    if (UseAuth("get")) fetchProfile();
  }, []);

  /**
   * changes the organization ID, and if mid-analysis, sends user back to Analysis page, and clears current analysis data
   * @param {*} id
   */
  function handleOrganizationChange(id) {
    if (organizationMemo.id != id) {
      //only do the following if the organizationId was changed
      organizationMemo.setId(id);
      analysisStepsViewMemo.setState({
        analysis_type: 0,
        input_method: 0,
      });
      clearLocalDb();
      const location = window.location.pathname.split("/");
      if (
        location?.length >= 2 &&
        (location[1] == "assessment" || location[1] == "compare")
      )
        navigate("/simulations");
    }
  }

  return (
    <>
      <AppBar
        position="static" //why are there 2 position elements?
        sx={{
          bgcolor: isImpersonation ? "rgb(144, 202, 249)" : "#F8F8F8",
          position: "relative", //why are there 2 position elements?
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        className="no-print"
      >
        <Toolbar>
          {isAuth && (
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2, paddingBottom: 2 }}
              onClick={() => setSideDrawer(!sideDrawer)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Stack direction={"row"} spacing={2} justifyContent={"flex-start"}>
            <Link to={disableAllButtons ? "/project" : "/"} sx={{ pt: "0.8%" }}>
              <img
                //todo: note: I don't like that I've hard-set this minwidth in px; but I couldn't find any other way to make it work; someone should look into finding a more dynamic width measurement for varying screen sizes
                style={{ height: "50px", width: "auto" }}
                src={logo}
                alt="logo"
              />
            </Link>

            {isImpersonation ? (
              <Alert
                component={Box}
                severity="info"
                action={
                  <Tooltip title="Exit Impersonation">
                    <Button
                      color="inherit"
                      size="small"
                      startIcon={<Close />}
                      onClick={exitImpersonationClicked}
                    >
                      Exit Impersonation
                    </Button>
                  </Tooltip>
                }
                sx={{ width: "100%" }}
              >
                Impersonating {profile?.first_name} {profile?.last_name}; signed
                in as {trueProfile?.first_name} {trueProfile?.last_name}
              </Alert>
            ) : null}
          </Stack>
          <Typography sx={{ flexGrow: 1 }} />
          {organizationMemo.id && // hide organization name, unless the user has selected one
            Object.keys(organizationMemo.lookup).length > 1 &&
            organizationMemo.id in organizationMemo.lookup && (
              <Typography color="primary">
                {organizationMemo.lookup[organizationMemo.id]}
              </Typography>
            )}
          <Typography sx={{ flexGrow: 1 }} />
          {!isAuth ? (
            <>
              <Button
                color="primary"
                component={Link}
                to={"/login"}
                style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              >
                Login
              </Button>
              <Button color="primary" component={Link} to={""}>
                Home
              </Button>
            </>
          ) : (
            <>
              <Button
                id="profile-dropdown"
                aria-controls={profileOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={profileOpen ? "true" : undefined}
                onClick={handleClickProfile}
                style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
                endIcon={profileOpen ? <ExpandLess /> : <ExpandMore />}
              >
                {profile ? (
                  stringTruncate(profile.first_name, 20, 20)
                ) : (
                  <Skeleton animation="wave" width={50} height={50} />
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={profileOpen}
                onClose={handleCloseProfile}
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer + 2,
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                }}
                PaperProps={{ elevation: 0, sx: { mt: 1 } }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 52,
                      height: 52,
                      fontSize: "28px",
                      backgroundColor: "#387af6",
                      fontWeight: "500",
                    }}
                  >
                    {profile?.first_name?.charAt(0)?.toUpperCase()}
                  </Avatar>
                  <div
                    style={{
                      color: "#282c34",
                      fontSize: "14px",
                      marginTop: "5px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    {profile?.first_name
                      ? stringCapitalize(profile.first_name)
                      : "Not Available"}{" "}
                    {profile?.last_name
                      ? stringCapitalize(profile.last_name)
                      : ""}
                  </div>
                </div>
                <Divider sx={{ bgcolor: "#92a4b6" }} variant="middle" />
                <MenuItem
                  component={Link}
                  to="/profile"
                  onClick={handleCloseProfile}
                  style={{ marginTop: "14px" }}
                >
                  <Avatar
                    style={{
                      width: 28,
                      height: 28,
                      marginLeft: -3.5,
                      marginRight: 11,
                    }}
                  />
                  Profile
                </MenuItem>
                {/* Organization Profile */}
                {profile && accessRights.admin.update_organization_profile && (
                  <MenuItem
                    component={Link}
                    to={`/organizationProfile?orgId=${
                      profile[`${profile.organization_type}_id`]
                    }`}
                    disabled={
                      window.location.pathname == "/organizationProfile"
                    }
                  >
                    <ListItemIcon>
                      <BadgeTwoTone fontSize="medium" />
                    </ListItemIcon>
                    My Organization
                  </MenuItem>
                )}
                {/* Organization sub-dropdown */}
                {Object.keys(organizationMemo.lookup).length > 1 && (
                  <MenuItem
                    onMouseEnter={(e) => setOrgAnchorEl(e.currentTarget)}
                    onMouseLeave={() => setOrgAnchorEl(null)}
                  >
                    <ListItemIcon>
                      <CorporateFareIcon fontSize="medium" />
                    </ListItemIcon>
                    Organization
                    <Menu
                      sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 3,
                        pointerEvents: "none", //prevents the popover from covering the entire page
                      }}
                      open={Boolean(orgAnchorEl)}
                      onChange={handleOrganizationChange}
                      onClose={() => setOrgAnchorEl(null)}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "center",
                      }}
                      anchorEl={orgAnchorEl}
                      disableAutoFocus
                      disableEnforceFocus
                    >
                      <span style={{ pointerEvents: "auto" }}>
                        <MenuItem onClick={() => handleOrganizationChange("")}>
                          All Organizations
                        </MenuItem>
                        {Object.entries(organizationMemo.lookup).map(
                          ([id, orgName]) => (
                            <MenuItem
                              onClick={() => handleOrganizationChange(id)}
                              key={`organization_${id}`}
                            >
                              {orgName}
                            </MenuItem>
                          )
                        )}
                      </span>
                    </Menu>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setTicketDialogOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <InfoOutlinedIcon fontSize="medium" />
                  </ListItemIcon>
                  Submit an issue
                </MenuItem>
                {isImpersonation && (
                  <MenuItem onClick={exitImpersonationClicked}>
                    <ListItemIcon>
                      <Close fontSize="medium" />
                    </ListItemIcon>
                    Exit Impersonation
                  </MenuItem>
                )}
                <MenuItem onClick={logOutItemClicked}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="medium" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isAuth && (
        <SideDrawer
          sideDrawer={sideDrawer}
          setSideDrawer={setSideDrawer}
          isAuth={isAuth}
        />
      )}

      {/* Jira ticket dialog box */}
      <Dialog
        open={ticketDialogOpen}
        onClose={() => setTicketDialogOpen(false)}
      >
        <JiraTicket
          setTicketDialogOpen={setTicketDialogOpen}
          profile={profile}
        />
      </Dialog>
      {/* logout dialog box */}
      <Logout
        logoutState={logoutState}
        setLogoutState={setLogoutState}
        isImpersonation={Boolean(trueProfile)}
      />
    </>
  );
}

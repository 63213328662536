import Sort from "@mui/icons-material/Sort";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useContext, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { unitLargeAbbr } from "../../../static/constants/systems_of_measurement";
import { getUnits } from "../../secondary/unitConversions";
import {
  getLabel,
  MFM_to_AMPM,
  Military_to_MFM,
  roundNumber,
} from "../../utils";

const stepSize = 180; //the time, in minutes, between each step

export default function RouteDefinitionTimeTable({
  data: allData,
  invalidBlocks,
}) {
  const units = getUnits();
  const [sort, setSort] = useState(0); //flag to determine unsorted (0), ascending sort (odd number), or descending sort (even number)
  const [sortAnchorEl, setSortAnchorEl] = useState(null); //used to position the dropdown for selecting sorts

  const { logo } = useContext(DataContext);

  const chartRef = useRef(null);

  const suggestedMax = useMemo(() => {
    let max = Math.max(
      ...allData.map((val) => Military_to_MFM(val.dh_end_time))
    );
    if (max > 1440) return Math.round(max / stepSize) * stepSize;
    else return 1440;
  }, [allData]);

  /** @type {Array} copy of data */
  let data = allData.map((row) => {
    const start = Military_to_MFM(row.dh_st_time);
    let end = Military_to_MFM(row.dh_end_time);
    if (start > end || row.next_day) end += 1440;
    return {
      ...row,
      dh_st_time: start,
      dh_end_time: end,
    };
  });
  // alt data, for testing
  // [
  //   { blockId: "test", dh_st_time: 100, dh_end_time: 200 },
  //   { blockId: "test 2", dh_st_time: 500, dh_end_time: 1000 },
  //   { blockId: "test 3", dh_st_time: 1250, dh_end_time: 1500 },
  //   ...allData,
  // ];

  const sortOptions = [
    { label: "Start Time", order: "asc" },
    { label: "Start Time", order: "desc" },
    { label: "End Time", order: "asc" },
    { label: "End Time", order: "desc" },
    { label: "Total Time", order: "asc" },
    { label: "Total Time", order: "desc" },
  ];

  /** opens the sort selection dropdown */
  const handleOpenSortMenu = (e) => setSortAnchorEl(e.currentTarget);
  /** closes the sort selection dropdown */
  const handleCloseSortMenu = (e) => setSortAnchorEl(null);
  /** selects a sort and closes the the dropdown */
  const handleSortSelect = (e) => {
    setSort(e.currentTarget.value);
    setSortAnchorEl(null);
  };

  //NOTE: if there is ever more than 3 different sorting Algorithms (6 cases) below, consider moving sort functions into sortOptions object list
  switch (sort) {
    case 0: // start time, ascending
      data = data.sort(
        (entry1, entry2) => entry1.dh_st_time - entry2.dh_st_time
      );
      break;
    case 1: //start time, descending
      data = data.sort(
        (entry1, entry2) => entry2.dh_st_time - entry1.dh_st_time
      );
      break;
    case 2: //end time, ascending
      data = data.sort(
        (entry1, entry2) => entry1.dh_end_time - entry2.dh_end_time
      );
      break;
    case 3: //end time, descending
      data = data.sort(
        (entry1, entry2) => entry2.dh_end_time - entry1.dh_end_time
      );
      break;
    case 4: //total time, ascending
      data = data.sort(
        (entry1, entry2) =>
          entry1.dh_end_time -
          entry1.dh_st_time -
          (entry2.dh_end_time - entry2.dh_st_time)
      );
      break;
    case 5: //total time, descending
      data = data.sort(
        (entry1, entry2) =>
          entry2.dh_end_time -
          entry2.dh_st_time -
          (entry1.dh_end_time - entry1.dh_st_time)
      );
      break;
    default: //unsorted (sort by ID)
      data = data.sort((entry1, entry2) => entry1.id - entry2.id);
      break;
  }

  const timeRange = data.map((block) => block.dh_end_time - block.dh_st_time);

  //computes the min/max timeRanges, for use in determining range of color of bars
  let max = Math.max(...timeRange);
  let min = Math.min(...timeRange);

  max = max - min ? max - min : 1;
  //max variable is replaced with the total range of distances; if range is 0 (max==min),
  //then set range to 1 (to avoid dividing by 0)

  const colors = timeRange
    .map((time) => 200 - Math.floor(200 * ((time - min) / max)))
    .map((value, index) =>
      invalidBlocks?.has(data[index].blockId)
        ? "red"
        : `rgba(${value},${value},255)`
    );

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    indexAxis: "y",
    interaction: { intersect: true, mode: "index" },
    scales: {
      x: {
        ticks: {
          font: { size: 16 },
          callback: (val) => MFM_to_AMPM(val),
          stepSize: stepSize,
        },
        suggestedMax: suggestedMax,
        min: 0,
        stacked: true,
      },
      y: {
        ticks: { display: false },
        title: { display: true, text: getLabel("blocks"), font: { size: 16 } },
        stacked: true,
      },
    },
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: "Operation Timetable",
        font: { size: 19 },
      },
      tooltip: {
        position: "nearest",
        callbacks: {
          title: (value) => `${getLabel("block_id")}: ${value[0].label}`,
          label: (value) => [
            `${MFM_to_AMPM(value.raw[0])} - ${MFM_to_AMPM(value.raw[1])}`,
            `${roundNumber(value.raw[2])} ${unitLargeAbbr[units]}`,
          ],
        },
        xAlign: "left",
        // yAlign: "bottom",
      },
      zoom: {
        pan: { enabled: true, mode: "y", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "y" },
      },
    },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: data.map((block) => block.blockId),
    datasets: [
      {
        label: "Time on route",
        data: data.map((block) => [
          block.dh_st_time,
          block.dh_end_time,
          block.distance,
        ]),
        borderRadius: 5, //curves the bars
        borderSkipped: false, //curve affects both sides
        // borderWidth: 5,
        // inflateAmount: 5,
        // backgroundColor: "rgba(53, 162, 235)", //for a single, blueish color
        backgroundColor: colors, //for a range of colors based around a given value
        // backgroundColor: colors, // for a direct mapping of colors
      },
    ],
  };

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "right", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <Chip
          label={sortOptions[sort].label}
          icon={
            <Sort
              className={
                sortOptions[sort].order === "asc" ? "rotate-icon-3" : ""
              }
            />
          }
          onClick={handleOpenSortMenu}
        />
        <Menu
          open={Boolean(sortAnchorEl)}
          anchorEl={sortAnchorEl}
          onClose={handleCloseSortMenu}
          PaperProps={{
            style: { minWidth: sortAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {sortOptions.map((value, index) => (
            <MenuItem
              key={`${value.label}_${value.order}`}
              value={index}
              onClick={handleSortSelect}
            >
              <ListItemIcon>
                <Sort
                  className={value.order === "asc" ? "rotate-icon-3" : ""}
                />
              </ListItemIcon>
              {value.label}
            </MenuItem>
          ))}
        </Menu>

        <Chip
          label="Reset Zoom"
          onClick={() => chartRef?.current?.resetZoom()}
        />
      </Stack>
      <Bar
        style={{ padding: "1%" }}
        ref={chartRef}
        options={chartOptions}
        data={chartData}
      />
      <div
        style={{ right: "calc(35px + 1%)", bottom: "35px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
    </div>
  );
}

import Cookie from "js-cookie";
import currency from "../../static/constants/currency.json";
import { roundNumber } from "../utils";

/**
 * sets the unit cookie
 * @param {Object} param0
 * @param {String} param0.simulation_version the version of the simulation, for use in determining which version of each page should render
 * @param {String} param0.simulation_name the name of the simulation, for use in simulation subtitle pop-up
 * @param {number} param0.project_id the id of the project, for use in simulation subtitle's "back to project analyses" button
 * @param {number} param0.project_type the type of the project, for use in table headers lookups (and other labels)
 * @param {String} param0.depot_name the name of the depot, also for use in simulation subtitle pop-up
 * @param {String} [param0.currency_code="USD"] the currency code, for exchange rate and unit lookup in corresponding exchange rate json
 * @param {1|2} [param0.unit=2] the unit of measurement, 1 is for metric, 2 is for imperial
 * @param {String} [param0.feeder_id] the (op†ional) feeder id of the depot
 */
function setAnalysisCookies({
  simulation_version,
  simulation_name,
  project_id,
  project_type,
  depot_name,
  currency_code = "USD",
  unit = 2,
  feeder_id = undefined,
}) {
  Cookie.set("simulation_version", simulation_version);
  Cookie.set("simulation_name", simulation_name); //sets the simulation name cookie, for the subtitle on pages (the one with the info card)
  Cookie.set("project_id", project_id); //used for "return to project analyses" button in subtitle
  Cookie.set("project_type", project_type); //used for table header lookups (and other labels)
  Cookie.set("depot_name", depot_name); //sets the depot name, also for subtitle
  Cookie.set("currency_code", currency_code); // sets currency code, for exchange rate and unit lookups
  Cookie.set("unit", unit); //sets unit, for deciding whether to display as metric or imperial
  Cookie.set("feeder_id", feeder_id); //se†s the feeder_id (an optional field from the depot)
}

/** clears out the simulation-specific cookies */
function clearAnalysisCookies() {
  Cookie.remove("simulation_version");
  Cookie.remove("simulation_name");
  Cookie.remove("project_id");
  Cookie.remove("project_type");
  Cookie.remove("depot_name");
  Cookie.remove("currency_code");
  Cookie.remove("unit");
  Cookie.remove("feeder_id");
}

/**
 * gets the unit cookie
 */
function getUnits() {
  return Number(Cookie.get("unit")) || 2;
}

/**
 * formats money in the user's desired currency (DOES NOT CONVERT, ONLY FORMATS)
 * @param {Number} money
 */
const currencyFormat = (money) =>
  Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: Cookie.get("currency_code") || "USD", //consider mapping with currency.json
  }).format(money);

/**
 * converts and formats money to the user's desired currency
 * @param {Number} money
 */
const currencyDisplay = (money) => currencyFormat(unitMoney(money));

/**
 * gets the currency symbol, for display purposes (usually during editting)
 * @param {String} [unitOverride] an iso4217 currency code, that if provided, takes priority over all other conversions
 */
const currencySymbol = (unitOverride) =>
  (0)
    .toLocaleString(navigator.language, {
      style: "currency",
      currency: unitOverride || Cookie.get("currency_code") || "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

/**
 * converts a currency from US dollars into the analyses' associated currency based on exchange rates
 * @param {Number} money
 * @param {String} [unitOverride] an iso4217 currency code, that if provided, takes priority over all other conversions
 */
const unitMoney = (money, unitOverride) => {
  const currency_code = unitOverride || Cookie.get("currency_code") || "USD";
  return roundNumber(money * currency[currency_code].exchange_rate);
};

/**
 * converts a currency from US dollars into the analyses' associated currency based on exchange rates
 * @param {Number} money
 * @param {String} [unitOverride] an iso4217 currency code, that if provided, takes priority over all other conversions
 */
const unitMoneyWithoutRounding = (money, unitOverride) => {
  const currency_code = unitOverride || Cookie.get("currency_code") || "USD";
  return money * currency[currency_code].exchange_rate;
};

/**
 * converts a currency back into US dollars
 * @param {Number} money
 * @param {String} [unitOverride] an iso4217 currency code, that if provided, takes priority over all other conversions
 */
const unitPerMoney = (money, unitOverride) => {
  const currency_code = unitOverride || Cookie.get("currency_code") || "USD";
  return money / currency[currency_code].exchange_rate;
};

/**
 * converts miles to miles or Kilometers, depending on stored unit cookie
 * @param {Number} miles
 */
const unitMiles = (miles) => {
  const unit = getUnits();
  return unit == 1 ? miles * 1.609344 : miles;
};

/**
 * converts perMile to perMile or perKilometer equivalent, depending on the stored unit cookie
 * @param {Number} perMile
 */
const unitPerMile = (perMile) => {
  const unit = getUnits();
  return unit == 1 ? perMile / 1.609344 : perMile;
};

/**
 * converts feet to feet or meter equivalent, depending on the stored unit cookie
 * NOTE: does not work on the resourceInventory page, as the stored unit cookie is analysis-specific
 * @param {Number} feet
 * @param {Boolean|undefined} unitOverride if True, force the conversion to metric, if False, don't convert (exists primarily for resource inventory)
 */
const unitFeet = (feet, unitOverride = undefined) => {
  const unit = getUnits();
  return unitOverride ?? unit == 1 ? Math.round(feet / 3.2808) : feet;
};

/**
 * if the unit is 1 (metric), converts fahrenheit to celsius
 * note: fahrenheit and celsius are based in the depot-level, and thus the "unit" cookie does not apply, must be passed in as argument
 * @param {Number} fahrenheit
 * @param {Number} unit the unit of measurement (1:metric; 2: fahrenheit)
 */
const unitTemperature = (fahrenheit, unit = 2) => {
  return unit == 1 ? ((fahrenheit - 32) * 5) / 9 : fahrenheit;
};

/**
 * if the unit is 1 (metric), converts celsius back to fahrenheit
 * note: fahrenheit and celsius are based in the depot-level, and thus the "unit" cookie does not apply, must be passed in as argument
 * @param {Number} celsius
 * @param {Number} unit the unit of measurement the user is in(1:metric; 2: fahrenheit)
 *
 */
const unitPerTemperature = (celsius, unit = 2) => {
  return unit == 1 ? Math.round((celsius * 9) / 5 + 32) : celsius;
};

/**
 * converts gallons to liters, depending on unit cookie
 * @param {Number} gallons
 */
const unitGallons = (gallons) => {
  const unit = getUnits();
  return unit == 1 ? gallons * 3.785 : gallons;
};

/**
 * converts liters back to gallons, depending on unit cookie
 * @param {Number} perGallon
 */
const unitPerGallon = (perGallon) => {
  const unit = getUnits();
  return unit == 1 ? perGallon / 3.785 : perGallon;
};

/**
 * converts Gallons of Gas Equivelent to kg, depending on unit cookie
 * @param {Number} GGE
 */
const unitGGE = (GGE) => {
  const unit = getUnits();
  return unit == 1 ? GGE * 2.567 : GGE;
};

/**
 * converts kg back to Gallons of Gas Equivalent, depending on unit cookie
 * @param {Number} perGGE
 */
const unitPerGGE = (perGGE) => {
  const unit = getUnits();
  return unit == 1 ? perGGE / 2.567 : perGGE;
};

/**
 * converts US tons to Metric tons, depending on unit cookie
 * @param {Number} tons
 */
const unitTon = (tons) => {
  const unit = getUnits();
  return unit == 1 ? tons / 1.102 : tons;
};

/**
 * converts liters back to gallons, depending on unit cookie
 * @param {Number} perTon
 */
const unitPerTon = (perTon) => {
  const unit = getUnits();
  return unit == 1 ? perTon * 1.102 : perTon;
};

/**
 * converts pounds to tons or tonnes, depending on units
 * @param {Number} pounds
 */
const unitPoundToTon = (pounds) => {
  const unit = getUnits();
  return unit == 1 ? pounds / 2205 : pounds / 2000;
};

/**
 * converts pounds to kg, depending on unit cookie
 * @param {Number} pounds
 */
const unitPound = (pounds) => {
  const unit = getUnits();
  return unit == 1 ? pounds / 2.205 : pounds;
};

/**
 * converts kg back to pounds, depending on unit cookie
 * @param {Number} perPound
 */
const unitPerPound = (perPound) => {
  const unit = getUnits();
  return unit == 1 ? perPound * 2.205 : perPound;
};

/**
 * converts acres to hectares, depending on unit cookie
 * @param {Number} acres
 */
const unitAcres = (acres) => {
  const unit = getUnits();
  return unit == 1 ? acres / 2.471 : acres;
};

export {
  clearAnalysisCookies,
  currencyDisplay,
  currencyFormat,
  currencySymbol,
  getUnits,
  setAnalysisCookies,
  unitAcres,
  unitFeet,
  unitGallons,
  unitGGE,
  unitMiles,
  unitMoney,
  unitMoneyWithoutRounding,
  unitPerGallon,
  unitPerGGE,
  unitPerMile,
  unitPerMoney,
  unitPerPound,
  unitPerTemperature,
  unitPerTon,
  unitPound,
  unitPoundToTon,
  unitTemperature,
  unitTon,
};


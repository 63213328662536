import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { createTicketURL } from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { errorHandler } from "../utils";

const ticketPriorities = [
  // {
  //   id: "1",
  //   name: "Highest",
  //   icon: <KeyboardDoubleArrowUpRounded sx={{ color: "#d04437" }} />,
  //   description: "This problem will block progress.",
  // },
  {
    id: "2",
    name: "High",
    description: "Serious problem that could halt progress.",
    // icon: <KeyboardArrowUpRounded sx={{ color: "#f15C75" }} />,
  },
  {
    id: "3",
    name: "Medium",
    description: "Has the potential to affect progress.",
    // icon: <DragHandleRounded sx={{ color: "#f79232" }} />,
  },
  {
    id: "4",
    name: "Low",
    description: "Minor problem or easily worked around.",
    // icon: <KeyboardArrowDownRounded sx={{ color: "#2a63f6" }} />,
  },
  // {
  //   id: "5",
  //   name: "Lowest",
  //   description: "Trivial problem with little or no impact on progress.",
  //   icon: <KeyboardDoubleArrowDownRounded sx={{ color: "#4382f5" }} />,
  // },
];

const ticketTypes = [
  {
    id: "6",
    name: "Bug",
    // icon: <BugReportTwoTone sx={{ color: "#d04437" }} />,
    description: "Bugs track problems or errors.",
  },
  {
    id: "7",
    name: "New Feature Request",
    // icon: <AddCircle sx={{ color: "#77a956" }} />,
    description: "A feature to add to the site.",
  },
  {
    id: "1",
    name: "Engineering Support",
    // icon: <HelpCenter sx={{ color: "#f79232" }} />,
    description: "For customer support issues.",
  },
];

/**
 * @param {Object} props
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setTicketDialogOpen //to close the dialog box
 * @param {Object} props.profile the user profile
 * @param {String} props.first_name
 * @param {String} props.last_name
 * @param {String} props.email
 */
export default function JiraTicket({ setTicketDialogOpen, profile }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { snackBarElement } = useContext(SnackBarContext);

  /**
   * Sends updated user profile details to backend
   * @param {SubmitEvent} event
   */
  function handleSubmitTicket(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${UseAuth("get")}`,
    };

    const request_body = {
      requestTypeId: formJSON.requestTypeId,
      requestFieldValues: {
        summary: formJSON.summary,
        description: `${formJSON.description}\n\nPriority: ${formJSON.priority}\nFrom Page: ${window.location.href}\nReported By: ${profile.first_name} ${profile.last_name}.\nEmail: ${profile.email}`,
      },
    };

    setButtonLoading(true);

    fetch(createTicketURL, {
      method: "POST",
      headers,
      body: JSON.stringify(request_body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Ticket Submitted", "success");
          setTicketDialogOpen(false);
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to create Jira Ticket"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong",
          "error",
          4000
        );
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }

  return (
    <>
      <DialogTitle>Submit a ticket</DialogTitle>
      <Container component="form" onSubmit={handleSubmitTicket}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="priority"
                name="priority"
                label="Priority"
                required
                fullWidth
                select
                defaultValue={"Medium"}
              >
                {ticketPriorities.map((priority) => (
                  <MenuItem
                    key={priority.name}
                    value={priority.name}
                    title={priority.description}
                  >
                    {priority.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="requestTypeId"
                name="requestTypeId"
                label="Request Type"
                required
                fullWidth
                select
                defaultValue="1"
              >
                {ticketTypes.map((requestTypeId) => (
                  <MenuItem
                    key={requestTypeId.name}
                    value={requestTypeId.id}
                    title={requestTypeId.description}
                  >
                    {requestTypeId.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="summary"
                name="summary"
                label="Summary"
                required
                fullWidth
                type="text"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                name="description"
                label="Description"
                fullWidth
                type="text"
                multiline
                maxRows={4}
              />
            </Grid>
          </Grid>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTicketDialogOpen(false)}>Cancel</Button>
          <LoadingButton loading={buttonLoading} type="submit">
            Create ticket
          </LoadingButton>
        </DialogActions>
      </Container>
    </>
  );
}

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Menu, MenuItem, Skeleton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { fleetManualFields } from "../assessmentPages/pages/fleetManual";
import {
  exportCSV,
  exportXLSX,
  jsxToString,
  parseFromValuesOrFunc,
} from "../utils";

/**
 *
 * @param {Object} param0
 * @param {import("material-react-table").MRT_TableInstance<never>} param0.table the material react table instance to be exported
 * @param {{CSV: Boolean, XLSX: Boolean, "EVopt Template": Boolean}} [param0.downloadOptions={CSV: true, XLSX: true, "Manual Import": false}] the options to include in the dropdown list
 * @param {String} param0.fileName name of the file
 * @param {boolean} [param0.disabled=false] whether or not the button is disabled (should be true until table is done rendering)
 */
export default function MRT_DownloadButton({
  table,
  fileName = "data",
  disabled = false,
  downloadOptions: customDownloadOptions,
}) {
  /** @type {[EventTarget & HTMLButtonElement]} */
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const downloadOptions = {
    CSV: true,
    XLSX: true,
    "EVopt Template": false,
    ...customDownloadOptions,
  };

  const MenuItemLookup = {
    CSV: exportCsvFromMRT,
    XLSX: exportXlsxFromMRT,
    "EVopt Template": exportAsFleetManualFromMRT,
  };

  function handleClose() {
    setAnchorEl(null);
  }

  /**
   * exports a material-react-table's data as a CSV
   * @param {MouseEvent} event
   */
  function exportCsvFromMRT(event) {
    event.preventDefault();

    //gets the columns keys (accessorKeys) in order
    const { columnOrder, columnVisibility } = table.getState();

    //NOTE: THIS WILL HIDE ALL COLUMNS THAT ARE NOT ACTIVELY BEING DISPLAYED
    const colKeys = columnOrder.filter(
      (colKey) =>
        !colKey.startsWith("mrt-row-") && //filters out mrt-generated columns, like "edit row" and "select"
        columnVisibility[colKey] != false //note: visible columns are either true or undefined, so must check `!= false`
    );
    let csvData = [];
    //adds the column header row
    const columnDefs = table._getColumnDefs();
    csvData.push(
      colKeys.map((colKey) => {
        const columnDef = columnDefs.find(
          (columnDef) => columnDef.id == colKey
        );
        let header =
          //if Header JSX is defined, use that; otherwise, use the default header
          parseFromValuesOrFunc(columnDef.Header, {
            table /**, header, column */, // note: technically, Header also expects {header, column} values, but it's not necessary in the current implementation
          }) ?? columnDef.header;
        header = jsxToString(header);
        if (columnDef.units) header += ` (${columnDef.units})`;
        return header;
      })
    );

    //adds the body data
    csvData = csvData.concat(
      table.getCoreRowModel().rows.map((row) => {
        const columnLookup = row._getAllCellsByColumnId();
        return colKeys.map((colKey) => {
          const cell = columnLookup[colKey];
          // if the Cell render was defined, use that, otherwise use the default value
          let renderedCellValue =
            cell.column.columnDef?.Cell?.({
              table,
              row,
              cell,
            }) ??
            cell.getValue() ??
            "N/A"; // if no data was found, render "N/A" (ideally, shouldn't happen)
          renderedCellValue = jsxToString(renderedCellValue);
          if (
            typeof cell.column.columnDef?.units == "string" &&
            renderedCellValue?.endsWith(cell.column.columnDef?.units)
          )
            // if the units is not undefined, and at the end of the rendered value, then remove the unit from the cell, as the units is instead defined in the header
            renderedCellValue = renderedCellValue
              .slice(
                0,
                renderedCellValue.length - cell.column.columnDef.units.length
              )
              .trim();
          return renderedCellValue;
        });
      })
    );

    exportCSV(csvData, fileName);
    handleClose();
  }

  /**
   * exports a material-react-table's data as a XLSX
   * @param {MouseEvent} event
   */
  function exportXlsxFromMRT(event) {
    event.preventDefault();

    //gets the columns keys (accessorKeys) in order
    const { columnOrder, columnVisibility } = table.getState();

    //NOTE: THIS WILL HIDE ALL COLUMNS THAT ARE NOT ACTIVELY BEING DISPLAYED
    const colKeys = columnOrder.filter(
      (colKey) =>
        !colKey.startsWith("mrt-row-") && //filters out mrt-generated columns, like "edit row" and "select"
        columnVisibility[colKey] != false //note: visible columns are either true or undefined, so must check `!= false`
    );

    let headerLookup = {};
    //gets the column header row labels
    const columnDefs = table._getColumnDefs();
    colKeys.forEach((colKey) => {
      const columnDef = columnDefs.find((columnDef) => columnDef.id == colKey);
      const header =
        //if Header JSX is defined, use that; otherwise, use the default header
        parseFromValuesOrFunc(columnDef.Header, {
          table /**, header, column */, // note: technically, Header also expects {header, column} values, but it's not necessary in the current implementation
        }) ?? columnDef.header;
      headerLookup[colKey] = jsxToString(header);
      if (columnDef.units) headerLookup[colKey] += ` (${columnDef.units})`;
    });

    //adds the body data
    const xlsxData = table.getCoreRowModel().rows.map((row) => {
      let rowData = {};
      const columnLookup = row._getAllCellsByColumnId();
      colKeys.forEach((colKey) => {
        const cell = columnLookup[colKey];
        // if the Cell render was defined, use that, otherwise use the default value
        let renderedCellValue =
          cell.column.columnDef?.Cell?.({
            table,
            row,
            cell,
          }) ??
          cell.getValue() ??
          "N/A"; // if no data was found, render "N/A" (ideally, shouldn't happen)
        rowData[headerLookup[colKey]] = jsxToString(renderedCellValue);
        if (
          typeof cell.column.columnDef?.units == "string" &&
          rowData[headerLookup[colKey]]?.endsWith(cell.column.columnDef?.units)
        )
          // if the units is not undefined, and at the end of the rendered value, then remove the unit from the cell, as the units is instead defined in the header
          rowData[headerLookup[colKey]] = rowData[headerLookup[colKey]]
            .slice(
              0,
              rowData[headerLookup[colKey]].length -
                cell.column.columnDef.units.length
            )
            .trim();
      });
      return rowData;
    });

    exportXLSX(xlsxData, fileName);
    handleClose();
  }

  /**
   * exports a material-react-table's data in the format expected by manual input
   * @param {MouseEvent} event
   */
  function exportAsFleetManualFromMRT(event) {
    event.preventDefault();

    //gets the columns keys (accessorKeys)
    const columns = fleetManualFields();

    //adds the body data
    const xlsxData = table.getCoreRowModel().rows.map((row) => {
      let rowData = {};
      const columnLookup = row._getAllCellsByColumnId();
      //assign all values in the row
      columns.forEach(
        (column) =>
          (rowData[column.label] = columnLookup[column.key].getValue())
      );
      return rowData;
    });

    exportXLSX(xlsxData, "Fleet Operation Data", { excludeHeader: true });
    handleClose();
  }

  return (
    <>
      <Tooltip title={disabled ? "Loading..." : "Download Table"}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {disabled
          ? [
              <Skeleton variant="wave" key="item_1">
                {/* same content both times, to ensure loading boxes are same width (should always match longest label) */}
                <MenuItem>Export as XLSX</MenuItem>
              </Skeleton>,
              <Skeleton variant="wave" key="item_2">
                <MenuItem>Export as XLSX</MenuItem>
              </Skeleton>,
            ]
          : Object.keys(downloadOptions)
              .filter((option) => downloadOptions[option])
              .map((label) => (
                <MenuItem key={label} onClick={MenuItemLookup[label]}>
                  Export as {label}
                </MenuItem>
              ))}
      </Menu>
    </>
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  appliedLicenseStatusURL,
  appliedLicenseURL,
  licensesURL,
} from "../../static/constants/backendRoutes";
import materialReactTableOptions from "../../static/constants/defaultMaterialReactTableOptions";
import UseAuth from "../auth/useAuth";
import {
  dialogGridItemProps,
  dialogGridLabelProps,
  dialogTextFieldProps,
} from "../dialogs/masterDataDialogs";
import { errorHandler } from "../utils";

/**
 *
 * @param {Object} param0
 * @param {import("material-react-table").MRT_Row<never>} param0.row
 * @param {((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)} param0.onClose
 */
export default function LicensingOptions({ row, onClose }) {
  const [dialogView, setDialogView] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  /** @type {[{applied_license: {}, list_licenses: []}]} */
  const [data, setData] = useState(undefined);
  const [expiredDialog, setExpiredDialog] = useState(false);

  const { snackBarElement } = useContext(SnackBarContext);
  const { accessRights } = useContext(DataContext);

  useEffect(() => {
    // fetches data that might be used in the dropdown options
    function fetchData() {
      const headers = { Authorization: `Token ${UseAuth("get")}` };

      //applied license status used in "license status" and "update license" views
      const appliedLicenseStatusPromise = accessRights.admin
        .list_applied_licenses
        ? fetch(
            `${appliedLicenseStatusURL}?organization_id=${row.original.id}`,
            { method: "GET", headers }
          ).then((res) => {
            if (res.ok) return res.json().then(({ data }) => data);
            else
              return res.json().then(({ errors: { internal_error_code } }) => {
                if (internal_error_code == 40931)
                  setExpiredDialog(true); //expired license scenario
                else
                  errorHandler(
                    res,
                    snackBarElement,
                    "Failed to get applied license data"
                  );
                return null;
              });
          })
        : {};

      // license list used in "change license" view
      const licenseListPromise = accessRights.admin.list_licenses
        ? fetch(licensesURL, { method: "GET", headers }).then((res) => {
            if (res.ok) return res.json().then(({ data }) => data);
            else {
              errorHandler(res, snackBarElement, "Failed to get licenses list");
              return [];
            }
          })
        : [];

      Promise.all([appliedLicenseStatusPromise, licenseListPromise])
        .then(([appliedLicenseData, licenseListData]) =>
          setData({
            applied_license: appliedLicenseData,
            license_list: licenseListData,
          })
        )
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Something went wrong",
            "error",
            5000
          );
        });
    }

    fetchData();
  }, [row, accessRights]);

  const optionsList = [
    {
      label: "License Status",
      disabled: !accessRights.admin.list_applied_licenses,
      /** @type {import("@mui/material").DialogProps} */
      dialogProps: { maxWidth: "md" },
      component: () => (
        <LicenseStatusDialog
          licenseData={data?.applied_license}
          onClose={onClose}
        />
      ),
    },
    {
      label: "Update License",
      disabled: !(
        accessRights.admin.update_applied_license && data?.applied_license
      ),
      dialogProps: { maxWidth: "sm" },
      component: () => (
        <UpdateLicenseDialog
          onClose={onClose}
          licenseData={data?.applied_license}
        />
      ),
    },
    {
      label: "Change License",
      disabled: !(
        accessRights.admin.list_licenses &&
        accessRights.admin.update_applied_license &&
        data
      ),
      /** @type {import("@mui/material").DialogProps} */
      dialogProps: { maxWidth: "md" },
      component: () => (
        <ChangeAppliedLicenseDialog
          onClose={onClose}
          data={data}
          organization_id={row.original.id}
        />
      ),
    },
    {
      label: "Delete",
      disabled: !accessRights.admin.expire_applied_license,
      dialogProps: { maxWidth: "sm" },
      component: () => (
        <DeleteAppliedLicenseDialog
          onClose={onClose}
          licenseData={data?.applied_license}
          row={row.original.name}
        />
      ),
    },
  ];

  /**
   * opens a dialog
   * @param {MouseEvent} e
   */
  function handleDialogOpen(e) {
    e.preventDefault();
    setDialogOpen(true);
    setDialogView(e.currentTarget.value);
  }

  return (
    <>
      {optionsList.map(({ label, disabled }, index) => (
        <MenuItem
          key={label}
          value={index}
          onClick={handleDialogOpen}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
      {/* general dialog (opened by any one of the above menu items) */}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={dialogOpen}
        onClose={onClose}
        {...optionsList[dialogView]?.dialogProps}
      >
        <DialogTitle>{optionsList[dialogView]?.label}</DialogTitle>
        {optionsList[dialogView]?.component()}
      </Dialog>
      {/* expired license dialog (opened if the license is expired) */}
      <Dialog
        open={expiredDialog}
        onClose={() => {
          setExpiredDialog(false);
          onClose();
        }}
      >
        <DialogTitle>Expired License</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          Your trial license has expired. Please contact an admin at{" "}
          <a
            href={
              "mailto:EVOPTSupport@microgridlabs.com?subject=" +
              "License Renewal Request" +
              "&body=" +
              "I'd like to discuss renewing my license with your company." +
              "%0D%0A%0D%0A" + //two newlines
              "Thank You!"
            }
            target="_blank"
          >
            EVOPTSupport@microgridlabs.com
          </a>{" "}
          for renewal.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setExpiredDialog(false);
              onClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * displays a bar chart of an organization's applied license status data
 * (shared in both license view and organization profile view)
 * @param {Object} param0
 * @param {Object} param0.licenseData
 */
export function LicenseStatus({ licenseData }) {
  /** @type {import("chart.js").ChartData} */
  const barChartData = {
    labels: [
      `Users ${licenseData.overview[1].usage}/${licenseData.overview[1].num_users}`,
      `Projects ${licenseData.overview[0].usage}/${licenseData.overview[0].num_projects}`,
    ],
    datasets: [
      {
        label: "Usage",
        data: [licenseData.overview[1].usage, licenseData.overview[0].usage], //licenseData.overview.map(({ usage }) => usage), // lol, can't use map because the overview data is in the opposite order of the display!
        backgroundColor: "#357af680",
      },
      {
        label: "Remaining",
        data: [
          licenseData.overview[1].num_users - licenseData.overview[1].usage,
          licenseData.overview[0].num_projects - licenseData.overview[0].usage,
        ],
        backgroundColor: "#80808040",
      },
    ],
  };

  /** @type {import("chart.js").ChartOptions} */
  const barChartOptions = {
    indexAxis: "y", //flips the bar chart to be horizontal
    maintainAspectRatio: false, //allows for variable height
    scales: {
      x: {
        suggestedMax: Math.max(
          licenseData.overview[1].num_users,
          licenseData.overview[0].num_projects
        ),
        stacked: true,
      },
      y: { stacked: true, ticks: { font: { size: 18 }, color: "black" } },
    },
    plugins: { legend: { display: false } },
  };

  return (
    <Container>
      <Typography variant="h6" display="flex" justifyContent="space-between">
        <span>
          Current Plan: <span className="header-green">{licenseData.name}</span>
        </span>
        {licenseData.validity.num_days_left != "" ? (
          <span
            style={{
              color:
                licenseData.validity.num_days_left > 10
                  ? "blue"
                  : licenseData.validity.num_days_left > 5
                  ? "orange"
                  : "red",
            }}
          >
            {licenseData.validity.num_days_left} day(s) left of trial
          </span>
        ) : (
          <span />
        )}
      </Typography>
      <Box>
        <Bar data={barChartData} options={barChartOptions} />
      </Box>
    </Container>
  );
}

//license options components

function LicenseStatusDialog({ licenseData, onClose }) {
  return (
    <>
      {licenseData ? (
        <DialogContent>
          <LicenseStatus licenseData={licenseData} />
        </DialogContent>
      ) : licenseData === null ? (
        <DialogContent sx={{ textAlign: "center" }}>
          <img
            src={"https://geek.design/wp-content/uploads/2019/01/404_Pages.png"}
            width="100%"
          />
        </DialogContent>
      ) : (
        <DialogContent sx={{ textAlign: "center" }}>
          <CircularProgress />
          <br /> <br /> Loading...
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
}

function UpdateLicenseDialog({ onClose, licenseData }) {
  const [inputData, setInputData] = useState({
    id: licenseData.id,
    num_projects: licenseData.overview[0].num_projects,
    num_users: licenseData.overview[1].num_users,
    add_days: 0, //just an arbitrary default value
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  function handleUpdate(e) {
    e.preventDefault();
    setLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = inputData;

    fetch(appliedLicenseURL, {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) onClose();
        else errorHandler(res, snackBarElement);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement(
          "Network Error: Failed to update license",
          "error",
          5000
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleUpdate}>
      <Grid container alignItems="center" spacing={1.5}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography variant="h6" textAlign="left">
            Current Plan:{" "}
            <span className="header-green">{licenseData.name}</span>
          </Typography>
        </Grid>
        <Grid {...dialogGridLabelProps}>User Usage:</Grid>
        <Grid {...dialogGridItemProps}>
          {licenseData.overview[0].usage} User(s)
        </Grid>
        <Grid {...dialogGridLabelProps}>User Limit</Grid>
        <Grid {...dialogGridItemProps}>
          <TextField
            {...dialogTextFieldProps}
            type="number"
            name="num_users"
            value={inputData.num_users}
            onChange={handleInputChange}
            InputProps={{
              inputProps: {
                max: 999,
                min: licenseData.overview[1].usage,
                step: 1,
              },
            }}
          />
        </Grid>
        <Grid {...dialogGridLabelProps}>Project Usage:</Grid>
        <Grid {...dialogGridItemProps}>
          {licenseData.overview[0].usage} Project(s)
        </Grid>
        <Grid {...dialogGridLabelProps}>Project Limit</Grid>
        <Grid {...dialogGridItemProps}>
          <TextField
            {...dialogTextFieldProps}
            type="number"
            name="num_projects"
            value={inputData.num_projects}
            onChange={handleInputChange}
            InputProps={{
              inputProps: {
                max: 999,
                min: licenseData.overview[0].usage,
                step: 1,
              },
            }}
          />
        </Grid>
        {licenseData.validity.num_days_left && (
          <>
            <Grid {...dialogGridLabelProps} md="6">
              Add days:
            </Grid>
            <Grid {...dialogGridItemProps} md="6">
              <TextField
                {...dialogTextFieldProps}
                type="number"
                name="add_days"
                value={inputData.add_days}
                onChange={handleInputChange}
                InputProps={{ inputProps: { max: 999, min: 0, step: 1 } }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} type="submit">
          Update
        </LoadingButton>
      </DialogActions>
    </form>
  );
}

export function ChangeAppliedLicenseDialog({ onClose, data, organization_id }) {
  const [loading, setLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState(
    data.applied_license
      ? {
          [data.applied_license.license_id]: true,
        }
      : {}
  );

  function handleChange(e) {
    e.preventDefault();
    setLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = {
      license_id: Object.keys(rowSelection)[0],
      organization_id: organization_id,
    };

    fetch(appliedLicenseURL, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) res.json().then(({ data }) => onClose(data));
        else errorHandler(res, snackBarElement);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement(
          "Network Error: Failed to change license",
          "error",
          5000
        );
      })
      .finally(() => setLoading(false));
  }

  const columns = useMemo(
    /** @returns {import("material-react-table").MRT_ColumnDef<never> []} */
    () => [
      { header: "Name", accessorKey: "name" },
      { header: "Description", accessorKey: "description" },
      { header: "Project Limit", accessorKey: "num_projects" },
      { header: "User Limit", accessorKey: "num_users" },
    ],
    []
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: data.license_list ?? [],
    columns,
    state: { rowSelection, density: "spacious" },

    //miscellaneous options
    enableTopToolbar: false,
    enableBottomToolbar: false,

    //row selection options
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(), //enables row click
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <DialogContent>
        <MaterialReactTable table={table} />
      </DialogContent>
      <DialogActions>
        {/* NOTE: Important that the onclose is fired without the event */}
        <Button onClick={() => onClose()}>Cancel</Button>
        <LoadingButton
          loading={loading}
          disabled={!Object.keys(rowSelection).length}
          onClick={handleChange}
        >
          Change
        </LoadingButton>
      </DialogActions>
    </>
  );
}

function DeleteAppliedLicenseDialog({
  onClose,
  licenseData,
  organization_name,
}) {
  const [loading, setLoading] = useState(false);
  const { snackBarElement } = useContext(SnackBarContext);

  function handleDelete() {
    setLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = { id: licenseData.id };

    fetch(appliedLicenseURL, {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          snackBarElement.current.displayToast("Deleted Applied License");
          onClose();
        } else errorHandler(res, snackBarElement);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error: failed to delete",
          "error"
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <DialogContent>
        Are you sure you want to delete the applied license for{" "}
        {organization_name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loading || !licenseData?.id}
          onClick={handleDelete}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
}

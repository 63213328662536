import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import Subheader from "../../secondary/subheader";
import { errorHandler, getLabel, getLocalData } from "../../utils";
import BatterySizingGraph from "../graphs/batterySizingGraph";
import { rowDataConversion } from "../pages/batterySizing";

export default function VehiclesBattery({ vehicleData }) {
  const [data, setData] = useState([]);

  const [vehicleInfoLookup, setVehicleInfoLookup] = useState({}); //used in the batterySizingGraph column calculations, and bulkUpdate (update battery sizing) data set
  const [routeEnergyInputs, setRouteEnergyInputs] = useState({
    //used when calculating fleet and charger sizing
    operating_year: 12,
    veh_count: 1, //NOTE: veh_count is unused, but left in this setState, because the value exists in fetched object (in case we need it later)
  });
  const [subheaderContent, setSubheaderContent] = useState([]);
  const { snackBarElement } = useContext(SnackBarContext);

  function updateSubheader(
    data,
    operatingYear = routeEnergyInputs?.operating_year
  ) {
    let [uniqueVehicleModels, sumInfeasibleSurplus, feasibleCount] = [[], 0, 0];

    data.forEach((row) => {
      if (!uniqueVehicleModels.includes(row.vehicleModel))
        uniqueVehicleModels.push(row.vehicleModel);
      const vehicleInfo = vehicleInfoLookup[row.vehicleModel];
      const SoC =
        row.batter_size *
        (1 - (vehicleInfo.max_soc - vehicleInfo.min_soc) / 100);
      const batterySize = row.batter_size - SoC;
      const surplus =
        row.batter_size -
        (batterySize - batterySize * 0.97 ** operatingYear) -
        SoC -
        row.detailed_energy.total_energy;
      sumInfeasibleSurplus += surplus < 0 ? surplus : 0;
      feasibleCount += !row.feasible;
    });

    setSubheaderContent([
      {
        value: uniqueVehicleModels.length,
        label: "Unique Vehicle Battery Sizes",
      },
      { value: feasibleCount, label: `Total Feasible ${getLabel("blocks")}` },
      {
        value: data.length - feasibleCount,
        label: `Total Infeasible ${getLabel("blocks")}`,
      },
      {
        value: Math.round(Math.abs(sumInfeasibleSurplus)).toLocaleString(),
        label: "Daily Energy Shortfall (kWh)",
      },
    ]);
  }

  useEffect(() => {
    if (data?.length) updateSubheader(data, routeEnergyInputs?.operating_year);
  }, [data]);

  useEffect(() => {
    //todo: see if you can make the useEffect moreEfficient by moving the backend calls to the end, and updating the batter_size implementations to be the only part that is still loading while waiting for the backend to respond
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        //get routeEnergyInputs, for when calculating the fleet and charger sizing
        const operating_year = await getLocalData("routeEnergy", "input").then(
          ({ input }) => {
            if (input) setRouteEnergyInputs(input); //todo: replace this state with a useRef() equivalent
            return input?.operating_year;
          }
        );

        //retrieves blocks from the localDb
        const { data: currentBlocks } = await getLocalData("battery");

        let blockEnergyData = currentBlocks.blocks.filter(
          (row) => row?.tableData?.checked || row.checked
        );
        const vehLookup = {}; //for more efficient finding in battery sizing chart
        vehicleData.forEach((veh) => (vehLookup[veh.model] = veh));
        setVehicleInfoLookup(vehLookup);

        blockEnergyData.forEach((row, index) => {
          row.id = index;
          rowDataConversion(row, vehLookup, operating_year);
        });

        setData(blockEnergyData);
      } catch (e) {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to fetch resources");
        else {
          snackBarElement.current.displayToast(
            "Something went wrong in charger data retrieval",
            "warning"
          );
          console.log(e);
        }
      }
    }

    fetchData();
  }, [vehicleData]);

  return (
    <div>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          <BatterySizingGraph
            allData={data}
            routeEnergyInputs={routeEnergyInputs}
            vehicleInfoLookup={vehicleInfoLookup}
            updateSubheader={updateSubheader}
          />
        </Paper>
      </Container>
      <br />
      <br />
    </div>
  );
}

import MaterialTable, { MTableToolbar } from "@material-table/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useRef, useState } from "react";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  cloneSimulationURL,
  customerURL,
  partnerURL,
  projectURL,
  simulationURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { Icons, errorHandler } from "../utils";

const defaultBulkDuplicateData = {
  project_id: "",
  simulations: [],
  receiver_org_id: "",
};

function AdminAnalysisView() {
  const [data, setData] = useState([]);
  //used to identify which row to duplicate the data of in handleDuplicateID
  const [duplicateRowData, setDuplicateRowData] = useState(undefined);
  const [bulkDuplicateData, setBulkDuplicateData] = useState({
    ...defaultBulkDuplicateData,
  });
  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);
  const [isBulkDuplicateDialogOpen, setIsBulkDuplicateDialogOpen] = useState(0); //0 is closed, 1 is open, 2 is loading/submitting
  const [projectLookup, setProjectLookup] = useState({});
  const [organizationLookup, setOrganizationLookup] = useState({});

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const materialTableRef = useRef();

  const simLookup = {};
  data.forEach((sim) => (simLookup[sim.id] = sim.name));

  useEffect(() => {
    /** fetch all existing simulations from backend, and creates an organization lookup JSON */
    function fetchData() {
      const headers = { Authorization: `Token ${UseAuth("get")}` };

      /** fetches all simulations */
      fetch(`${simulationURL}?organization_id=-1`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response
              .json()
              .then(({ data: simulationsData }) => setData(simulationsData));
          } else {
            errorHandler(response, snackBarElement, "Failed to fetch analyses");
            setData([]);
          }
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network failure: failed to get simulation data",
            "error",
            5000
          );
        })
        .finally(() => setDataFetchError(true));

      /** fetches all simulations */
      fetch(projectURL + "?organization_id_list=-1", {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json().then(({ data: project_list }) => {
              const project_lookup = {};
              project_list.forEach((project) => {
                project_lookup[project.id] = project.name;
              });
              setProjectLookup(project_lookup);
            });
          } else {
            errorHandler(response, snackBarElement, "Failed to fetch projects");
          }
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network failure: failed to get projects",
            "error",
            5000
          );
        });

      /** fetch all partner-level organizations */
      const partners = fetch(partnerURL, { method: "GET", headers }).then(
        (response) => {
          if (response.ok) {
            return response.json().then(({ data }) => data);
          } else {
            errorHandler(response, snackBarElement, "Failed to get partners");
            return [];
          }
        }
      );

      /** fetch all customer-level organizations */
      const customers = fetch(customerURL, { method: "GET", headers }).then(
        (response) => {
          if (response.ok) {
            return response.json().then(({ data }) => data);
          } else {
            errorHandler(response, snackBarElement, "Failed to get partners");
            return [];
          }
        }
      );

      /** combine partner and customer organization lists, and create a organization lookup table */
      Promise.all([partners, customers])
        .then(([partners, customers]) => {
          const organizationLookup = { 1: "Site" };
          partners.forEach((el) => (organizationLookup[el.id] = el.name));
          customers.forEach((el) => (organizationLookup[el.id] = el.name));
          setOrganizationLookup(organizationLookup);
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Failed to get organizations",
            "warning",
            5000
          );
        });
    }
    //only fetch if user is a site-level admin
    if (accessRights.master_data.create) fetchData();
  }, [accessRights.master_data.create]);

  /**
   * Creates a copy of a simulation in a specified analysis
   * @param {Object} newRow the new simulation row
   * @return {Promise<(0|1)>} 0 for success, 1 for failure
   */
  const handleDuplicateRow = (newRow) => {
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = {
      simulations: [{ id: duplicateRowData.id }],
      receiver_org_id: newRow.organization_id,
    };

    //create a copy of a simulation
    return fetch(cloneSimulationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then(({ data: res_data }) => {
            const postedRow = { ...duplicateRowData };
            delete postedRow.tableData;
            postedRow.id = res_data[0].id;
            postedRow.project_id = res_data[0].project_id;
            postedRow.name = res_data[0].name;
            postedRow.updated_at = new Date().toISOString();
            postedRow.created_at = postedRow.updated_at;
            postedRow.organization_id = newRow.organization_id;

            setData([...data, postedRow]);
            snackBarElement.current.displayToast(
              `Duplicated Analysis into ${
                organizationLookup[newRow.organization_id]
              }`
            );
            return 0;
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to duplicate analysis"
          );
          return 1;
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error: Failed to duplicate analysis",
          "error"
        );
        return 1;
      });
  };

  /**
   * Creates a bunch of duplicates of simulations
   * @param {React.FormEvent<HTMLDivElement>} e
   */
  function handleBulkDuplicate(e) {
    e.preventDefault();
    setIsBulkDuplicateDialogOpen(2);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    //create a copy of a simulation
    fetch(cloneSimulationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        ...bulkDuplicateData,
        simulations: bulkDuplicateData.simulations.map((sim_id) => ({
          id: sim_id,
        })),
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(({ data: res_data }) => {
            const new_data = bulkDuplicateData.simulations.map((sim_id, i) => {
              const postedRow = { ...data.find((sim) => sim.id == sim_id) };
              delete postedRow.tableData;
              postedRow.id = res_data[i].id;
              postedRow.project_id = res_data[i].project_id;
              postedRow.name = res_data[i].name;
              postedRow.updated_at = new Date().toISOString();
              postedRow.created_at = postedRow.updated_at;
              postedRow.organization_id = bulkDuplicateData.receiver_org_id;
              return postedRow;
            });

            setData(data.concat(new_data));
            snackBarElement.current.displayToast(
              `Duplicated Analyses into ${
                organizationLookup[bulkDuplicateData.receiver_org_id]
              }`
            );
            handleBulkDuplicateClose();
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to duplicate analyses"
          );
          setIsBulkDuplicateDialogOpen(1);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error: Failed to duplicate Analyses",
          "error"
        );
        setIsBulkDuplicateDialogOpen(1);
      });
  }

  function handleBulkDuplicateClose() {
    setIsBulkDuplicateDialogOpen(0);
    setTimeout(
      () => setBulkDuplicateData({ ...defaultBulkDuplicateData }),
      [500]
    );
  }

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    {
      title: "Organization",
      field: "organization_id",
      lookup: organizationLookup,
      emptyValue: "Loading...",
      validate: (rowData) => rowData?.organization_id in organizationLookup,
      editComponent: (props) => (
        <TextField
          fullWidth
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value || ""}
          label="Organization"
          select
          SelectProps={{
            //set an upper limit to the height of the dropdown
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
          }}
        >
          {Object.entries(organizationLookup).map(([key, value]) => (
            <MenuItem key={`organization_${key}`} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    { title: "Name", field: "name", editable: "never" },
    { title: "Description", field: "description", editable: "never" },
    {
      title: "Analysis Type",
      field: "analysis_type",
      lookup: TYPE_STRINGS.SIMULATION_TYPE,
      editable: "never",
    },
    {
      title: "Updated at",
      field: "updated_at",
      type: "date",
      defaultSort: "desc",
      editable: "never",
      hidden: true,
    },
    {
      title: "Created On",
      field: "created_at",
      type: "date",
      defaultSort: "desc",
      editable: "never",
    },
    {
      title: "Status",
      field: "completed",
      lookup: { true: "Complete", false: "In Progress" },
      editable: "never",
    },
  ];

  return (
    <Container fixed maxWidth="xl">
      <br />
      <br />
      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
        <MaterialTable
          title="Manage Analyses"
          columns={columns}
          data={data}
          icons={Icons()}
          localization={{
            body: {
              emptyDataSourceMessage: dataFetchError ? (
                "No records to display"
              ) : (
                <>
                  <CircularProgress />
                  <br />
                  Loading...
                </>
              ),
            },
            toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
          }}
          tableRef={materialTableRef}
          actions={[
            {
              icon: ContentCopyIcon,
              tooltip: "Duplicate & move Analysis",
              position: "row",
              onClick: (_event, rowData) => {
                if (rowData.analysis_type == 2) {
                  snackBarElement?.current?.displayToast(
                    "Cannot duplicate and move Comparison-type analyses",
                    "warning",
                    5000
                  );
                  return;
                }
                const materialTable = materialTableRef.current;
                //set the ID of the row to be duplicated, for use in handleDuplicateRow()
                setDuplicateRowData(rowData);
                materialTable.dataManager.changeRowEditing();
                materialTable.setState({
                  ...materialTable.dataManager.getRenderState(),
                  showAddRow: true,
                });
              },
              hidden: !accessRights.analysis.create_analysis,
            },
            {
              icon: ContentCopyIcon,
              tooltip: "Bulk Duplicate & Move Analyses",
              position: "toolbar",
              onClick: () => setIsBulkDuplicateDialogOpen(1),
              isFreeAction: true,
              hidden:
                !accessRights.analysis.create_analysis ||
                !Object.keys(projectLookup).length ||
                !data.length,
            },
          ]}
          components={{
            Toolbar: (props) => {
              let modifiedProps = { ...props };
              //removes the add row action
              const addRowActionIndex = modifiedProps.actions.findIndex(
                (action) => action.tooltip == "Add"
              );
              if (addRowActionIndex != -1)
                modifiedProps.actions.splice(addRowActionIndex, 1);

              return <MTableToolbar {...modifiedProps} />;
            },
          }}
          options={{ addRowPosition: "first" }}
          editable={{
            onRowAdd:
              (Object.keys(organizationLookup).length || undefined) &&
              ((newData) =>
                // When submitting a "dupicate row"
                new Promise((resolve, reject) => {
                  handleDuplicateRow(newData).then((status) => {
                    if (status == 0) resolve();
                    else reject();
                  });
                })),
          }}
        />
      </Paper>

      {/* bulk duplicate and move analysis dialog */}
      <Dialog
        maxWidth="md"
        fullWidth
        component="form"
        open={Boolean(isBulkDuplicateDialogOpen)}
        onClose={handleBulkDuplicateClose}
        onSubmit={handleBulkDuplicate}
      >
        <DialogTitle>Bulk Duplicate and Move Analyses</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              pt: 3,
              "& .MuiFormControl-root": {
                mx: 2,
              },
            }}
          >
            <TextField
              required
              fullWidth
              variant="outlined"
              value={bulkDuplicateData.project_id}
              label="Project"
              onChange={(e) =>
                setBulkDuplicateData({
                  ...bulkDuplicateData,
                  project_id: e.target.value,
                  simulations: [],
                })
              }
              select
              SelectProps={{
                MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
              }}
            >
              {Object.entries(projectLookup).map(([key, value]) => (
                <MenuItem key={`project_${key}`} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <FormControl fullWidth required>
              <InputLabel id="analyses-select">Analyses</InputLabel>
              <Select
                labelId="analyses-select"
                variant="outlined"
                value={bulkDuplicateData.simulations}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={`analysis_input_${value}`}
                        label={simLookup[value]}
                      />
                    ))}
                  </Box>
                )}
                label="Analyses"
                onChange={(e) =>
                  setBulkDuplicateData({
                    ...bulkDuplicateData,
                    simulations: e.target.value,
                  })
                }
                input={<OutlinedInput label="Analyses" />}
                MenuProps={{ PaperProps: { sx: { maxHeight: "60%" } } }}
                multiple={true}
                disabled={!bulkDuplicateData.project_id}
                sx={{
                  backgroundColor: !bulkDuplicateData.project_id
                    ? (theme) => theme?.palette?.action?.disabledBackground
                    : undefined,
                }}
              >
                {data
                  .filter(
                    (analysis) =>
                      analysis.project_id == bulkDuplicateData.project_id &&
                      analysis.analysis_type == 1
                  )
                  .map((analysis) => (
                    <MenuItem key={analysis.id} value={analysis.id}>
                      {analysis.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <br />
          <TextField
            required
            fullWidth
            variant="outlined"
            value={bulkDuplicateData.receiver_org_id}
            label="Target Organization"
            onChange={(e) =>
              setBulkDuplicateData({
                ...bulkDuplicateData,
                receiver_org_id: e.target.value,
              })
            }
            select
            SelectProps={{
              MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
            }}
            disabled={
              !bulkDuplicateData.project_id ||
              !bulkDuplicateData.simulations.length
            }
            sx={{
              maxWidth: "65%",
              backgroundColor:
                !bulkDuplicateData.project_id ||
                !bulkDuplicateData.simulations.length
                  ? (theme) => theme?.palette?.action?.disabledBackground
                  : undefined,
            }}
          >
            {Object.entries(organizationLookup).map(([key, value]) => (
              <MenuItem key={`organization_${key}`} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBulkDuplicateClose}>Cancel</Button>
          <LoadingButton loading={isBulkDuplicateDialogOpen == 2} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AdminAnalysisView;

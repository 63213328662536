import { createContext, useRef } from "react";
import SnackbarAlert from "../components/secondary/snackBar";

/** @typedef {import("react").MutableRefObject<{displayToast: import("../components/secondary/snackBar").displayToast}>} snackBarElement */

/** @type {import("react").Context<{snackBarElement: snackBarElement}>} */
export const SnackBarContext = createContext();

const SnackBarContextProvider = (props) => {
  /** @type {snackBarElement} */
  const snackBarElement = useRef();

  return (
    <>
      <SnackBarContext.Provider value={{ snackBarElement }}>
        {props.children}
      </SnackBarContext.Provider>
      <SnackbarAlert ref={snackBarElement} />
    </>
  );
};

export default SnackBarContextProvider;

import MaterialTable from "@material-table/core";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  customerURL,
  moveProjectURL,
  partnerURL,
  projectURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import NotAuthorized from "../secondary/notAuthorized";
import { Icons, errorHandler } from "../utils";

function AdminProjectView() {
  const [data, setData] = useState([]);
  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);
  const [organizationLookup, setOrganizationLookup] = useState({});

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    /**
     * gets existing projects from backend, and creates an organization lookup JSON
     */
    function fetchData() {
      /** if user is a site-level admin */
      if (accessRights.master_data.create) {
        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
        };

        /** get all projects */
        fetch(`${projectURL}?organization_id_list=-1`, {
          method: "GET",
          headers,
        })
          .then((response) => {
            if (response.ok) {
              response.json().then(({ data: projects }) => {
                //reversed, so that the most recent project is at top of table
                setData(projects.reverse());
                if (!projects.length) {
                  setDataFetchError(true);
                  snackBarElement.current.displayToast(
                    "No Projects found, please create a new project before continuing",
                    "info",
                    5000
                  );
                }
              });
            } else {
              errorHandler(
                response,
                snackBarElement,
                "Something went wrong getting existing projects"
              );
              setDataFetchError(true); //replaces "loading" message with a "no data found" message, if the projectData is an empty list
            }
          })
          .catch((e) => {
            console.log(e);
            snackBarElement.current.displayToast(
              "Network Error occurred while fetching project data, please try again later",
              "error",
              10000
            );
            setDataFetchError(true); //replaces "loading" message with a "no data found" message, if the projectData is an empty list
          });

        /** fetch all partner-level organizations */
        const partners = fetch(partnerURL, { method: "GET", headers }).then(
          (response) => {
            if (response.ok) {
              return response.json().then(({ data }) => data);
            } else {
              errorHandler(response, snackBarElement, "Failed to get partners");
              setDataFetchError(true);
              return [];
            }
          }
        );

        /** fetch all customer-level organizations */
        const customers = fetch(customerURL, { method: "GET", headers }).then(
          (response) => {
            if (response.ok) {
              return response.json().then(({ data }) => data);
            } else {
              errorHandler(response, snackBarElement, "Failed to get partners");
              setDataFetchError(true);
              return [];
            }
          }
        );

        /** combine partner and customer organization lists, and create a organization lookup table */
        Promise.all([partners, customers])
          .then(([partners, customers]) => {
            const organizationLookup = { 1: "Site" };
            partners.forEach((el) => (organizationLookup[el.id] = el.name));
            customers.forEach((el) => (organizationLookup[el.id] = el.name));
            setOrganizationLookup(organizationLookup);
          })
          .catch((e) => {
            console.log(e);
            snackBarElement?.current?.displayToast(
              "Failed to get organizations",
              "warning",
              5000
            );
          });
      }
    }

    fetchData();
  }, [accessRights.master_data.create]);

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    //format for the table
    {
      title: "Organization",
      field: "organization_id",
      lookup: organizationLookup,
      emptyValue: "Loading...",
      validate: (rowData) => rowData?.organization_id in organizationLookup,
      editComponent: (props) => (
        <TextField
          fullWidth
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value || ""}
          label="Organization"
          select
          SelectProps={{
            //set an upper limit to the height of the dropdown
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
          }}
        >
          {Object.entries(organizationLookup).map(([key, value]) => (
            <MenuItem key={`organization_${key}`} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      title: "Name",
      field: "name",
      editable: "never",
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
    },
  ];

  /**
   * moves the project to a new organization
   * @param {JSON} newData
   * @returns {Promise<-1|0>} 0 for success, -1 for failure
   */
  function handleUpdate(newData, oldData) {
    const body = {
      project_id: newData.id,
      receiver_organization_id: newData.organization_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${UseAuth("get")}`,
    };

    return fetch(moveProjectURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          const index = oldData.tableData.index;
          data[index] = newData;
          setData([...data]);
          snackBarElement?.current?.displayToast("Moved Project"); // remove this notification once organization_ids are added to project response, and replace with setData(update)
          return 0;
        } else {
          errorHandler(res, snackBarElement, "Failed to move project");
          return -1;
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network Error: Failed to move project",
          "error",
          5000
        );
        return -1;
      });
  }

  if (accessRights.projects.read_own_org_projects === null) {
    //if someone refreshes page, or modifies url to view, display loading until access rights are fetched
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    );
  } else if (!accessRights.master_data.create) {
    // if user doesn't have access rights to view the page
    return <NotAuthorized />;
  }

  return (
    <Container fixed maxWidth="xl">
      <br />
      <br />
      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
        <MaterialTable
          title="Move Projects"
          columns={columns}
          data={data}
          icons={Icons()}
          localization={{
            body: {
              emptyDataSourceMessage:
                //todo: look into if the below projectData.length is necessary
                dataFetchError || data.length ? (
                  // if an error occurs, display message
                  "No records to display"
                ) : (
                  // until the point that an error occurs or the data is retrieved, display a loading message
                  <>
                    <CircularProgress />
                    <br />
                    Loading...
                  </>
                ),
            },
            toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
          }}
          editable={{
            onRowUpdateCancelled:
              (accessRights.projects.update_project || undefined) &&
              (() =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve();
                  }, 100);
                })),
            onRowUpdate:
              ((accessRights.projects.update_project &&
                Object.keys(organizationLookup).length) ||
                undefined) &&
              ((newData, oldData) =>
                new Promise((resolve, reject) => {
                  handleUpdate(newData, oldData).then((status) => {
                    if (status == 0) resolve();
                    else reject();
                  });
                })),
          }}
        />
      </Paper>
    </Container>
  );
}

export default AdminProjectView;

import MaterialTable, { MTableEditRow } from "@material-table/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  organizationURL,
  partnerActivationToggleURL,
  partnerURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { Icons, errorHandler } from "../utils";
import BrandingOptions from "./brandingOptions";
import DefaultDialog, { ColoredCircle } from "./defaultDialog";

/**
 * React Component of each row's "Options" DropDown menu
 * TODO: Consider making this a util, once the page is finished
 * NOTE: This is made as a separate React Component to preserve the sorting of the materialtable when opening/closing the dialog box
 * @param {Object} props
 * @param {Object} props.rowData the material table's rowData
 * @param {Function} props.setData the setData function, to update the material table's contents if user Activates/deactivates the partner
 * @returns {ReactElement} dropdown menu, for the material table
 */
function RowActionsDropdown(props) {
  const { rowData, setData } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(0); // visibility of activate/deactivate(1) or delete(2) dialog box
  const [anchorEl, setAnchorEl] = useState(null); //anchor element, for positioning the dropdown
  const [loading, setLoading] = useState(false);
  const isOpen = Boolean(anchorEl); //dropdown open status

  const { snackBarElement } = useContext(SnackBarContext);
  const { accessRights } = useContext(DataContext);

  const actionsDropdown = {
    "View Profile": `/organizationProfile?orgId=${rowData?.id}`,
    "Manage Customers": `/customer?partner_id=${rowData?.id}`,
    "Manage Users": `/user?orgId=${rowData?.id}`,
  };

  /** displayed message text for toggling status */
  const statusText =
    isDialogOpen == 2
      ? "Delete"
      : rowData.is_active
      ? "Deactivate"
      : "Activate";

  /** opens the dropdown, and sets the location of the dropdown menu */
  function actionsOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function actionsClose() {
    setAnchorEl(null);
  }

  /** flips the selected row's "is_active" flag */
  function toggleRowIsActive() {
    setData((data) => {
      const x = data.findIndex((row) => row.id == rowData.id);
      data[x].is_active = !data[x].is_active;
      return [...data];
    });
  }

  /** Closes the "Activate/Deactivate" Dialog Box */
  function handleCloseDialog() {
    setIsDialogOpen(0);
    actionsClose();
  }

  /**
   * Activates/Deactivates the row's Partner Organization
   */
  function handleActivation() {
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ partner_id: rowData.id });
    fetch(partnerActivationToggleURL, { method: "PATCH", headers, body })
      .then((res) => {
        if (!res.ok) {
          //if status code is not 200, toggle back to orignal state
          errorHandler(res, snackBarElement);
          toggleRowIsActive();
        }
      })
      .catch((e) => {
        //if fetch fails, toggle back to original state
        console.log(e);
        toggleRowIsActive();
      });
    if (!rowData.is_active)
      snackBarElement.current.displayToast(
        "Reactivation Email sent to Partner's Admin User Account",
        "success"
      );

    toggleRowIsActive();
    handleCloseDialog();
  }

  /**
   * Deletes the row's Customer Organization
   */
  function handleDelete(e) {
    e.preventDefault();
    setLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({ organization_id_list: [rowData.id] });
    fetch(organizationURL, { method: "DELETE", headers, body })
      .then((res) => {
        if (res.ok) {
          setData((data) => [...data.filter((row) => row.id !== rowData.id)]);
          handleCloseDialog();
        } else {
          errorHandler(res, snackBarElement, "Failed to delete user");
        }
      })
      .catch((e) => {
        //if fetch fails, toggle back to original state
        console.log(e);
        snackBarElement?.current?.displayToast("Something went wrong", "error");
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Tooltip title="Open Actions">
        <Button
          variant="outlined"
          className="btn"
          onClick={(e) => actionsOpen(e)}
          endIcon={
            isOpen ? (
              <ExpandLess sx={{ pointerEvents: "none" }} />
            ) : (
              <ExpandMore sx={{ pointerEvents: "none" }} />
            )
          }
        >
          Options
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isOpen && !isDialogOpen}
        onClose={actionsClose} //causes clicking elsewhere to close box
      >
        {Object.entries(actionsDropdown).map(([label, route]) => (
          <MenuItem key={label} component={Link} to={route}>
            {label}
          </MenuItem>
        ))}
        <MenuItem
          disabled={!accessRights.admin.activate_deactivate_partner}
          onClick={() => setIsDialogOpen(1)}
        >
          {statusText}
        </MenuItem>
        <MenuItem
          disabled={!accessRights.admin.delete_organization}
          onClick={() => setIsDialogOpen(2)}
        >
          Delete
        </MenuItem>
        <BrandingOptions
          rowData={rowData}
          setData={setData}
          actionsClose={actionsClose}
        />
      </Menu>

      {/* activate/delete organization dialog */}
      <Dialog
        open={Boolean(isDialogOpen)}
        onClose={handleCloseDialog}
        onSubmit={isDialogOpen == 1 ? handleActivation : handleDelete}
        component="form"
        aria-labelledby="alert-dialog-title" //accessibility stuff
        aria-describedby="alert-dialog-description"
        transitionDuration={{ exit: 0 }}
      >
        <DialogTitle id="alert-dialog-title">{statusText} Partner?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {statusText.toLowerCase()} {rowData.name}?
            This will{" "}
            {isDialogOpen == 2
              ? "delete all contained users and customer organizations"
              : rowData.is_active
              ? "deactivate all contained users and customer organizations"
              : "send a re-activation email to a partner admin"}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            {statusText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function PartnerView() {
  const [data, setData] = useState([]);
  const [rerender, toggleRerender] = useState(false); //uses rerender, because the POST partner response does not contain the partner ID
  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);

  const { accessRights } = useContext(DataContext);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    async function fetchData() {
      try {
        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        await fetch(partnerURL, {
          method: "GET",
          headers: headers,
        }).then((res) => {
          if (res.ok) {
            return res.json().then((output) => {
              let partners = output.data;
              //todo: delete the below re-assignment
              partners = partners.map((element) => ({
                ...element,
                available_licenses: 3,
              }));
              setData(partners);
              setDataFetchError(true);
            });
          }
          //else
          errorHandler(
            res,
            snackBarElement,
            "Something went wrong retrieving partners"
          );
          setDataFetchError(true);
        });
      } catch (e) {
        console.log(e);
        snackBarElement.current.displayToast("Something Went Wrong", "error");
        setDataFetchError(true);
      }
    }

    fetchData();
  }, [rerender]);

  /**
   * gets the data from the submitted form, and sends it to onRowUpdate function using default material table functions
   * @param {SubmitEvent} event
   * @param {Object} props the material table's editRowData props
   */
  function formSubmit(event, props) {
    event.preventDefault();

    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    //placeholder data
    newDataJSON.num_users_active = 0;
    newDataJSON.num_customers_active = 0;
    newDataJSON.is_active = true;
    newDataJSON.available_licenses = 5;
    //default material table edit submit function (goes to onRowUpdate/onRowAdd function)
    props.onEditingApproved(props.mode, newDataJSON, props.data);
  }

  // Cancels the row edit and closes the dialog box
  function formClose(event, props) {
    //default material table edit cancel function
    props.onEditingCanceled(props.mode, props.data);
  }

  /** shows the form submission is loading (using pure JS, no react) */
  function showLoading() {
    document.getElementById("is_loading_button").style.display = "block";
    document.getElementById("is_not_loading_button").style.display = "none";
  }

  /** finishes the form submission's loading (using pure JS, no react) */
  function hideLoading() {
    document.getElementById("is_loading_button").style.display = "none";
    document.getElementById("is_not_loading_button").style.display = "block";
  }

  /**
   * updates the row with the submitted form data
   * @param {Object} newData the submitted form data
   * @returns {0|1} - 0 for success, 1 for failure
   */
  function handleRowAdd(newData) {
    showLoading();
    let formData = new FormData();
    for (let i in newData) formData.append(i, newData[i]);

    if (newData.enable_brand && !newData.logo.size) {
      snackBarElement.current.displayToast(
        "A logo is required to create brand",
        "warning",
        5000
      );
      hideLoading();
      return 1;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
    };

    return fetch(partnerURL, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((responseData) => {
            snackBarElement.current.displayToast(responseData.message);
            //TODO: consider replacing this with a different data update mechanism that fetches from backend
            // const dataAdd = [...data];
            // const index = data.length;
            // dataAdd[index] = newData;
            // setData([...dataAdd]);
            return 0;
          });
        }
        //else
        errorHandler(
          response,
          snackBarElement,
          "something went wrong while adding the new partner"
        );
        hideLoading();
        return 1;
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error occurred while adding the new partner",
          "error",
          10000
        );
        hideLoading();
        return 1;
      });
  }

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    {
      title: "Status",
      render: (rowData) => (
        <Stack direction="row" spacing={2}>
          <ColoredCircle
            color={{ true: "green", false: "red" }[rowData.is_active]}
            label={
              "Organization: " +
              { true: "Active", false: "Inactive" }[rowData.is_active]
            }
          />
          <ColoredCircle
            color={TYPE_STRINGS.BRAND_STATUS[rowData.brand_status].color}
            label={
              rowData?.subdomain
                ? `${rowData.subdomain}.*`
                : `Branding: ${
                    TYPE_STRINGS.BRAND_STATUS[rowData.brand_status].label
                  }`
            }
          />
        </Stack>
      ),
    },
    { title: "Name", field: "name" },
    { title: "Active Customers", field: "num_customers_active" },
    { title: "Active Users", field: "num_users_active" },
    { title: "Available Licenses", field: "available_licenses", hidden: true },
    {
      sorting: false,
      render: (rowData) => (
        <RowActionsDropdown rowData={rowData} setData={setData} />
      ),
    },
  ];

  return (
    <Container fixed maxWidth="xl">
      <br />
      <br />
      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
        <MaterialTable
          title="Partner"
          data={data}
          columns={columns}
          icons={Icons("Partner")}
          localization={{
            body: {
              emptyDataSourceMessage:
                dataFetchError || data.length ? (
                  // if an error occurs in project fetch OR if the data array has been populated, but the user filtered out all data, display this message in the table
                  "No records to display"
                ) : (
                  // until the point that an error occurs or the data is retrieved, display a loading message in table
                  <>
                    <CircularProgress />
                    <br />
                    Loading...
                  </>
                ),
            },
            toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
          }}
          options={{ actionsColumnIndex: -1 }}
          initialFormData={{
            name: "",
            website: "https://",
            description: "",
            phone_number: "",
            country_code: "",
            state_code: "",
            city: "",
            zipcode: "",
            admin_first_name: "",
            admin_last_name: "",
            admin_email: "",
            subdomain: "",
            enable_brand: false,
          }}
          editable={{
            onRowAdd:
              (accessRights.admin.create_partner || undefined) &&
              ((newData) =>
                new Promise((resolve, reject) =>
                  handleRowAdd(newData).then((status) => {
                    if (status == 0) {
                      toggleRerender(!rerender);
                      resolve();
                    } else reject();
                  })
                )),
          }}
          components={{
            EditRow: (props) => (
              <>
                <MTableEditRow {...props} />
                <Dialog
                  open={true}
                  component="form"
                  onSubmit={(event) => formSubmit(event, props)}
                  onClose={(event) => formClose(event, props)}
                  fullWidth
                  maxWidth="lg"
                >
                  <DefaultDialog data={props.data} pageview="partner" />
                  <DialogActions>
                    <Button onClick={(e) => formClose(e, props)}>Cancel</Button>
                    <LoadingButton
                      id="is_loading_button"
                      loading={true}
                      sx={{ display: "none" }}
                    >
                      Submit
                    </LoadingButton>
                    <Button id="is_not_loading_button" type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ),
          }}
        />
      </Paper>
    </Container>
  );
}

import Sort from "@mui/icons-material/Sort";
import { Box, Chip, Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { unitLargeMap } from "../../../static/constants/systems_of_measurement";
import { getUnits } from "../../secondary/unitConversions";
import { getLabel, MFM_to_AMPM, Military_to_MFM } from "../../utils";
import { VehicleModelDropdown } from "../commonComponents";

export default function RouteDefinitionGraph(props) {
  const chartRef = useRef(null);
  const [sort, setSort] = useState(undefined); //flag to determine unsorted, ascending sort, or descending sort
  const [selectedModel, setSelectedModel] = useState("all");

  const { logo } = useContext(DataContext);

  const units = getUnits();
  //a list of selected blocks (or all blocks, if none selected)
  let data = props.data
    .filter(
      (row) => selectedModel == "all" || selectedModel == row.vehicleModel
    )
    .map((entry) => ({
      blockId: entry.blockId,
      distance: entry.distance,
      dh_st_time: Military_to_MFM(entry.dh_st_time),
      dh_end_time: Military_to_MFM(entry.dh_end_time),
    }));

  useEffect(() => {
    if (props.data.length) props.updateSubheader(data);
  }, [selectedModel]);

  if (sort) {
    //true=> sort min->max
    data = data.sort((entry1, entry2) => entry1.distance - entry2.distance);
  } else if (sort != undefined) {
    //false=> sort: max->min
    data = data.sort((entry1, entry2) => entry2.distance - entry1.distance);
  } //undefined => unsorted

  const distances = data.map((block) => parseInt(block.distance));

  //computes the min/max distances, for use in determining range of color of bars
  let max = Math.max(...distances);
  let min = Math.min(...distances);

  max = max - min ? max - min : 1;
  //max variable is replaced with the total range of distances; if range is 0 (max==min),
  //then set range to 1 (to avoid dividing by 0)

  const colors = distances.map(
    //determines the R and G values of RGB for the bars
    (distance) => 200 - Math.floor(200 * ((distance - min) / max))
  );

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    plugins: {
      //displays the title of the chart
      title: {
        display: true,
        text: `Route / ${getLabel("block")} Overview`,
        font: { size: 19 },
      },
      //hides a weird subtitle thingy
      legend: { display: false },
      //creates a tooltip when hovering over a bar
      tooltip: {
        callbacks: {
          // title: (value) => ``
          label: (value) =>
            `Distance: ${value.formattedValue} ${unitLargeMap[units]}`,
          title: (value) =>
            `${getLabel("block_id")}: ${data[value[0].dataIndex].blockId}`,
          afterTitle: (value) =>
            `Depot Depart Time: ${MFM_to_AMPM(
              data[value[0].dataIndex]?.dh_st_time
            )}\nDepot Arrive Time:  ${MFM_to_AMPM(
              data[value[0].dataIndex]?.dh_end_time
            )}`,
        },
      },
      zoom: {
        pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "x" },
      },
    },
    scales: {
      x: {
        //replace labelled ticks with a title for the x-axis
        ticks: { display: false },
        title: { display: true, text: getLabel("blocks"), font: { size: 19 } },
      },
      //label the y-axis
      y: {
        // This more specific font property overrides the global property
        ticks: { font: { size: 16 } },
        title: {
          display: true,
          text: `Distance (${unitLargeMap[units]})`,
          font: { size: 19 },
        },
      },
    },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    //determines the name of each bar (x-axis)
    labels: data.map((block) => block.blockId),
    datasets: [
      {
        //determines the height of a bar (y-axis)
        data: data.map((block) => block.distance.toFixed(1)),
        backgroundColor: colors.map((value) => `rgba(${value},${value},255)`),
      },
    ],
  };

  //todo: can data ever be false? Consider removing "data &&" below
  return (
    data && (
      <div className="chartdiv">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VehicleModelDropdown
            data={props.data}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
          <Stack
            sx={{ justifyContent: "right", margin: "1%" }}
            direction="row"
            spacing={1}
          >
            <Chip
              label="Distance"
              icon={
                <Sort
                  className={`${!sort ? "rotate-icon-1" : "rotate-icon-2"}`}
                />
              }
              onClick={() => setSort(!sort)}
            />
            <Chip
              label="Reset Zoom"
              onClick={() => chartRef?.current?.resetZoom()}
            />
          </Stack>
        </Box>
        <div className="watermark-container">
          <img
            src={logo}
            width="auto"
            height="50px"
            className="watermark-img"
          />
        </div>
        <Bar ref={chartRef} options={chartOptions} data={chartData} />{" "}
      </div>
    )
  );
}

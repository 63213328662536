// NOTE: not in a .json, so that \xa0 can be used as a non-breaking space
export default {
  SIMULATION_TYPE: {
    1: "Assessment",
    2: "Comparison",
  },
  VEHICLE_TYPE: {
    1: "Transit",
    2: "Box\xa0Truck",
    3: "Taxi",
    4: "School\xa0Bus",
    5: "Light\xa0Duty\xa0Truck",
    6: "Passenger\xa0Vehicle",
    7: "Van",
    8: "Cutaway",
    9: "Step\xa0Van",
    10: "Bucket\xa0Truck",
    11: "Flat\xa0Bed\xa0Truck",
    12: "Tractor\xa0Trailer",
  },
  PROJECT_TYPE: {
    1: "Transit",
    2: "Last\xa0Mile",
    3: "Taxi",
    4: "School\xa0Bus",
    5: "Municipal",
    6: "Commercial",
  },
  ELECTRICITY_TYPE: {
    1: "AC",
    2: "DC",
  },
  CHARGING_TYPE: {
    1: "Sequential",
    2: "Parallel",
  },
  FUEL_TYPE: {
    ICE: "Diesel",
    GAS: "Gasoline",
    PROP: "Propane",
    CNG: "CNG",
  },
  // NOTE: not sure how I feel about having BRAND_STATUS contain the color field... it's inconsistent with rest of file
  BRAND_STATUS: {
    0: { label: "Not Created", color: "red" },
    1: { label: "Creating...", color: "yellow" },
    2: { label: "Enabled", color: "green" },
    3: { label: "Deleting...", color: "yellow" },
    4: { label: "Enabling...", color: "yellow" },
    5: { label: "Disabling...", color: "yellow" },
    6: { label: "Disabled", color: "orange" },
    7: { label: "Updating...", color: "yellow" },
  },
};

import Sort from "@mui/icons-material/Sort";
import { Box, Chip, Slider, Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { getLabel } from "../../utils";
import { VehicleModelDropdown } from "../commonComponents";
import { monthNames } from "../pages/financialAnalysis";

const colors = {
  traction: "#0b132b",
  HVAC: "#348aa7",
  aux: "#cdf7f6",
};

export function EnergyDefinitionGraph(props) {
  const chartRef = useRef(null);
  const [sort, setSort] = useState(undefined); //flag to determine unsorted, ascending sort, or descending sort
  const [selectedModel, setSelectedModel] = useState("all");
  const [hvacMonthSlider, setHvacMonthSlider] = useState(
    Object.entries(props.data?.[0]?.monthly_hvac || {}).reduce(
      (max, curr) =>
        max[1] < curr[1]
          ? [
              ...curr,
              monthNames
                .map((m) => m.toLowerCase())
                .findIndex((m) => m == curr[0]),
            ]
          : max,
      ["january", 0, 0]
    )[2]
  );

  const { logo } = useContext(DataContext);

  //a list of selected blocks (or all blocks, if none selected)
  let data = props.data
    .filter(
      (row) => selectedModel == "all" || selectedModel == row.vehicleModel
    )
    .map((entry) => {
      const HVAC =
        props.sim?.analysis_type_steps?.fleet_operation
          ?.route_energy_analysis != "1.0.1"
          ? entry.monthly_hvac?.[monthNames[hvacMonthSlider].toLowerCase()]
          : entry.detailed_energy.HVAC;
      const energy_calc =
        entry.detailed_energy.traction + HVAC + entry.detailed_energy.Aux;

      return {
        blockId: entry.blockId,
        traction: entry.detailed_energy.traction,
        HVAC,
        aux: entry.detailed_energy.Aux,
        energy: entry.detailed_energy.total_energy, //total energy, used for sorting
        energy_calc,
        detailed_energy: entry.detailed_energy,
        distance: entry.distance, //needed for the updateSubheader code
      };
    });

  useEffect(() => {
    if (data.length) props.updateSubheader(data, "energy_calc");
  }, [hvacMonthSlider, selectedModel]);

  if (sort) {
    //true=> sort min->max
    data = data.sort((entry1, entry2) => entry1.energy - entry2.energy);
  } else if (sort != undefined) {
    //false=> sort: max->min
    data = data.sort((entry1, entry2) => entry2.energy - entry1.energy);
  } //undefined => unsorted

  const allEnergy = data.map((block) => parseInt(block.energy));

  //computes the min/max energiess, for use in determining range of color of bars
  const max = Math.max(...allEnergy);

  //max variable is replaced with the total range of energiess; if range is 0 (max==min),
  //then set range to 1 (to avoid dividing by 0)

  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    interaction: { intersect: false, mode: "index" }, //combines all 3 bars into 1 tooltip
    plugins: {
      //displays the title of the chart
      title: {
        display: true,
        text: "Route Energy Overview",
        font: { size: 16 },
      },
      //creates a tooltip when hovering over a bar
      tooltip: {
        callbacks: {
          title: (value) =>
            `${getLabel("block_id")}: ${data[value[0].dataIndex].blockId}`,
          afterTitle: (value) =>
            `Total Energy: ${data[value[0].dataIndex].energy.toFixed(1)} (kWh)`,
          //adds (kWh) after the value
          label: (value) =>
            `${value.dataset.label}: ${value.formattedValue} (kWh)`,
        },
      },
      zoom: {
        pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "x" },
      },
    },
    scales: {
      x: {
        stacked: true,
        //replace labelled ticks with a title for the x-axis
        ticks: { display: false, font: { size: 16 } },
        title: { display: true, text: getLabel("blocks"), font: { size: 16 } },
      },
      //label the y-axis
      y: {
        suggestedMax: max,
        stacked: true,
        ticks: { font: { size: 16 } },
        title: { display: true, text: "Energy (kWh)", font: { size: 16 } },
      },
    },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    //determines the name of each bar (x-axis)
    labels: data.map((block) => block.blockId),
    datasets: [
      {
        label: "Traction Energy",
        data: data.map((block) => block.traction.toFixed(1)),
        backgroundColor: colors.traction,
      },
      {
        label: "HVAC Energy",
        data: data.map((block) => block.HVAC.toFixed(1)),
        backgroundColor: colors.HVAC,
      },
      {
        label: "Auxiliary Energy",
        data: data.map((block) => block.aux.toFixed(1)),
        backgroundColor: colors.aux,
      },
    ],
  };

  //todo: can data ever be false? Consider removing "data &&" below
  return (
    data && (
      <div className="chartdiv">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VehicleModelDropdown
            data={props.data}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
          {props.sim?.analysis_type_steps?.fleet_operation
            ?.route_energy_analysis != "1.0.1" && (
            <Slider
              value={hvacMonthSlider}
              marks={monthNames.map((month, i) => ({
                value: i,
                label: month.substring(0, 3),
              }))}
              color="secondary"
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => monthNames[value]}
              min={0}
              max={11}
              onChange={(e) => setHvacMonthSlider(e.target.value)}
              sx={{ maxWidth: "50%" }}
            />
          )}
          <Stack
            sx={{ justifyContent: "right", margin: "1%" }}
            direction="row"
            spacing={1}
          >
            <Chip
              label="Energy"
              icon={
                !sort ? (
                  <Sort className="rotate-icon-1" />
                ) : (
                  <Sort className="rotate-icon-2" />
                )
              }
              onClick={() => {
                setSort(!sort);
              }}
            />
            <Chip
              label="Reset Zoom"
              onClick={() => chartRef?.current?.resetZoom()}
            />
          </Stack>
        </Box>
        <div className="watermark-container">
          <img
            src={logo}
            width="auto"
            height="50px"
            className="watermark-img"
          />
        </div>
        <Bar ref={chartRef} options={chartOptions} data={chartData} />
      </div>
    )
  );
}

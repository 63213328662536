import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DataContext } from "../../../contexts/dataContext";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  annualLoadURL,
  chargerURL,
  emissionsURL,
  evAssessmentURL,
  simulationURL,
  vehicleURL,
} from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import stepInfo from "../../../static/constants/stepInfo";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import {
  errorHandler,
  getLabel,
  getLocalData,
  parseFromValuesOrFunc,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import FleetSizingGraph from "../graphs/fleetSizingGraph";
import FleetSizingTimeTable from "../graphs/fleetSizingTimeTable";

const STEP_NUMBER = 4;

const maintenanceConstants = {
  vehicle: {
    BEV: 0.53,
    ICE: 1.54,
    PROP: 0.5,
    CNG: 0.5,
    GAS: 0.5,
  },
  chargers: {
    AC: 10, //unused
    DC: 15, //unused
  },
  other: {},
};

const emissionsConstants = {
  ICE: { CO2: 22.45, NOX: 0.04, SO2: 0.0001065 },
  GAS: { CO2: 17.86, NOX: 0.0015, SO2: 0.00006 },
  PROP: { CO2: 12.68, NOX: 0.0015, SO2: 0 },
  CNG: { CO2: 10.765, NOX: 0.007, SO2: 0 },
};

const chipNameLookup = { 0: "Overview", 1: "Fleet Size", 2: "Timetable" };

/**
 * the fleet Sizing page
 * @returns {JSX} fleet Sizing component
 */
function FleetSizing() {
  const [chipView, setChipView] = useState(0); ///0 for display table, 1 for display chart 1, 2 for chart 2
  const [chipAnchorEl, setChipAnchorEl] = useState(null); //used to position the chipView dropdown
  /** @type {["BEV"|"ICE"]} */
  const [tableDisplay, setTableDisplay] = useState("BEV"); // identifies which primary table view to display
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showScenarioDialog, setShowScenarioDialog] = useState(false); //whether the "Select Charging Scenario" pop-up is shown
  const [isDataFetched, setIsDataFetched] = useState(false); //note: this page doesn't really need the datafetch error, d
  /** @type {[{infeasible_blocks: { model_breakdown: Array }, feasible_blocks: Array}]} */
  const [fleetData, setFleetData] = useState({
    infeasible_blocks: { model_breakdown: [] },
    feasible_blocks: [],
  }); //for the pages materialTable contents
  /** @type {[{blocks: {hvac: {cooling: Boolean, heating: Boolean}, weekend: {sat:Number, sun: Number, operate: Number}, end_date: string, start_date: string, resolution: Number}, routeEnergy: {data: Array, input: {operating_year: Number, veh_count: Number}}, battery: {data: Array, input: {}}}]} */
  const [submissionData, setSubmissionData] = useState();

  const [rowSelection, setRowSelection] = useState({ 0: true });
  const selectedRow = Object.keys(rowSelection)[0];

  const { snackBarElement } = useContext(SnackBarContext); //for the toast notifications when an error occurs
  const { accessRights } = useContext(DataContext);

  const navigate = useNavigate();

  const units = getUnits();

  /**
   * retrieves tableData from the frontend's indexdB
   */
  useEffect(() => {
    async function fetchData() {
      const indexedFleet = await getLocalData("fleetSizing");
      const selectedRow = indexedFleet?.input?.row;
      const localFleet = indexedFleet?.data;

      if (selectedRow) setRowSelection({ [selectedRow]: true }); //if this page has been previously visited, then re-use the previously selected row
      //if localFleet was stored on frontend, display it
      if (localFleet) setFleetData(localFleet);

      //get routeEnergyInputs, for when calculating the evAssessment
      const routeEnergyValuesPromise = getLocalData("routeEnergy");
      const blocksInputPromise = getLocalData("blocks", "input");
      const batterySizingPromise = getLocalData("battery");
      Promise.all([
        routeEnergyValuesPromise,
        blocksInputPromise,
        batterySizingPromise,
      ]).then(
        ([
          routeEnergy,
          { input: blocks },
          { data: batteryData, input: batteryInputs },
        ]) => {
          if (routeEnergy && blocks && batteryData?.blocks)
            setSubmissionData({
              routeEnergy,
              blocks,
              battery: {
                data: batteryData.blocks.filter((block) => block.checked),
                input: batteryInputs,
              },
            });
        }
      );

      setIsDataFetched(true);
    }

    fetchData();
  }, []);

  //parse out the separate data tables
  const bevData =
    fleetData?.feasible_blocks?.filter((row) => row?.feasible) || [];
  const selectedBevData = useMemo(
    () => (bevData.length ? [bevData[selectedRow]] : []),
    [bevData, selectedRow]
  );
  const iceData = fleetData?.infeasible_blocks?.model_breakdown || [];

  const bevColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Scenario",
        id: "scenario",
        Cell: ({ row }) => `Scenario ${+row.id + 1}`,
      },
      {
        header: "Rating",
        accessorKey: "charger_model.rating",
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kW")}
          </>
        ),
        sorting: false,
      },
      {
        header: "Charger Count",
        Header: (
          <>
            Charger
            <br />
            Count
          </>
        ),
        accessorKey: "num_chargers",
        sorting: false,
      },
      {
        header: "Vehicle Type",
        id: "veh_type",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-type`}>
              {TYPE_STRINGS.VEHICLE_TYPE[sub_row.veh_type]}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Vehicle Size",
        id: "size",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-size`}>
              {sub_row.veh_type == 1 ? (
                <>
                  {unitFeet(sub_row.size)} {unitWrapper(unitSmallMap[units])}
                </>
              ) : sub_row.veh_type == 4 ? (
                `Type ${sub_row.size}`
              ) : (
                `Class ${sub_row.size}`
              )}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Battery Capacity",
        Header: (
          <>
            Battery
            <br />
            Capacity
          </>
        ),
        id: "battery_capacity",
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-batteryCapacity`}>
              {sub_row.battery_capacity} {unitWrapper("kWh")}
            </div>
          )),
        sorting: false,
      },
      {
        header: `Total ${getLabel("blocks")}`,
        Header: (
          <>
            Total
            <br />
            {getLabel("blocks")}
          </>
        ),
        id: "block_count",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-total_blocks`}>
              {sub_row.num_blocks}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: `Feasible ${getLabel("blocks")}`,
        Header: (
          <>
            Feasible
            <br />
            {getLabel("blocks")}
          </>
        ),
        id: "num_blocks",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-feasible_blocks`}>
              {sub_row.num_feasible_blocks}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "ICE Needed",
        Header: (
          <>
            ICE
            <br />
            Needed
          </>
        ),
        id: "ice_fleet_size",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-ice_fleet_size`}>
              {sub_row.ice_fleet_size}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "BEV Needed",
        Header: (
          <>
            Equivalent
            <br />
            BEV
          </>
        ),
        id: "ev_fleet_size",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-ev_fleet_size`}>
              {sub_row.ev_fleet_size}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
      {
        header: "Replacement Ratio",
        Header: (
          <>
            Replacement
            <br />
            Ratio
          </>
        ),
        id: "replacement_ratio",
        Cell: ({ row }) =>
          row.original.fleet.model_breakdown.map((sub_row) => (
            <div key={`primaryBEV-${sub_row.model}-replacement_ratio`}>
              {roundNumber(sub_row.replacement_ratio)}
            </div>
          )),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        sorting: false,
      },
    ],
    []
  );

  const iceColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Vehicle Type",
        accessorKey: "veh_type",
        Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
      },
      {
        header: "Vehicle Size",
        accessorKey: "size",
        Cell: ({ row, cell }) => {
          const veh_type = row.getValue("veh_type");
          return veh_type == 1 ? (
            <>
              {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
            </>
          ) : veh_type == 4 ? (
            `Type ${cell.getValue()}`
          ) : (
            `Class ${cell.getValue()}`
          );
        },
      },
      { header: `Total ${getLabel("blocks")}`, accessorKey: "num_blocks" },
      {
        header: `Infeasible ${getLabel("blocks")}`,
        accessorKey: "num_infeasible_blocks",
      },
      { header: "ICE Vehicles", accessorKey: "ice_fleet_size" },
    ],
    []
  );

  /**
   * the "calculate EV Assessment button", retrieves the routeEnergy page's data,
   * creates the request body based off that data and the selected row (scenario),
   * then, sends a backend EV-Assessment request, saveing the response on both frontend and
   * backend
   * and then saves it on frontend and updates backend, before navigating to the
   * next page
   */
  const handleEvAssessment = async () => {
    setButtonLoading(true);

    const block_mapping_helper = (block) => ({
      //same block mapping from routeEnergy.js code
      blockId: block.blockId,
      dh_st_time: block.dh_st_time,
      dh_end_time: block.dh_end_time,
      distance: block.distance,
      startDepot: block.startDepot,
      endDepot: block.endDepot,
      vehicleEff: block.detailed_energy.updated_efficiency,
      vehicleModel: block.vehicleModel,
    });

    const body = {
      feasible_config: {
        settings: {
          bev_settings: {
            battery_lifetime: submissionData.routeEnergy.input.operating_year, //operating year from the routeEnergy page
            ...submissionData?.battery?.input?.settings?.bev_settings,
          },
        },
        charger_models: [bevData[selectedRow].charger_model.model], //the currently selected FleetSizingRow's chargerModel
        bev_model_charger_model_map: bevData[
          selectedRow
        ].fleet.model_breakdown.map((fleet) => ({
          vehicle_model: fleet.model,
          charger_model: bevData[selectedRow].charger_model.model,
        })),
        bev_inventory: bevData[selectedRow].fleet.model_breakdown.map(
          (fleet) => ({
            vehicle_model: fleet.model,
            num_vehicles: fleet.ev_fleet_size,
          })
        ),
        ice_equivalent_inventory: bevData[
          selectedRow
        ].fleet.model_breakdown.map((fleet) => ({
          vehicle_model: fleet.model,
          num_vehicles: fleet.ice_fleet_size,
        })),
        blocks: submissionData.battery.data
          .filter((row) => !row.feasible)
          .map(block_mapping_helper),
      },
      infeasible_config: {
        settings: {
          ice_settings: {
            ...submissionData?.battery?.input?.settings?.bev_settings,
          },
        },
        ice_inventory: iceData.map((fleet) => ({
          vehicle_model: fleet.model,
          num_vehicles: fleet.ice_fleet_size,
        })),
        blocks: submissionData.battery.data
          .filter((row) => row.feasible)
          .map(block_mapping_helper),
      },
    };

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.fleet_and_charger_sizing.depot_energy_analysis}`,
    };

    const selectedCharger = bevData[selectedRow].charger_model.model;

    fetch(evAssessmentURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        delete headers["Accept"];
        if (!response.ok) {
          errorHandler(
            response,
            snackBarElement,
            `Error running ${stepInfo[STEP_NUMBER + 1].label} Analysis`
          );
          setButtonLoading(false);
          return;
        }
        //else
        response.json().then(async ({ data: evAssessmentData }) => {
          //todo: get the backend to do all this extra evAssessment assignment stuff
          evAssessmentData.selectedCharger = selectedCharger;
          headers.Accept = `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`;
          const start_date = submissionData.blocks.start_date.split("-");
          const end_date = submissionData.blocks.end_date.split("-");
          const loadProfileBody = {
            block_schedule: submissionData.routeEnergy.data,
            load_profile: {
              full_power: evAssessmentData.load_profile.full_power,
            },
            ...submissionData.blocks,
            start_date: `${start_date[1]}/${start_date[2]}/${start_date[0]}`,
            end_date: `${end_date[1]}/${end_date[2]}/${end_date[0]}`,
            resolution: 15,
          };

          /** @type {Promise<0|1>} updates evAssessmentData with annual15, returns 0 for failure, 1 for success */
          const annualLoadPromise = fetch(annualLoadURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(loadProfileBody),
          })
            .then((annualLoadResponse) => {
              delete headers.Accept;
              if (!annualLoadResponse.ok) {
                errorHandler(
                  annualLoadResponse,
                  snackBarElement,
                  `Failed to perform ${
                    stepInfo[STEP_NUMBER + 1].label
                  } Analysis`
                );
                setButtonLoading(false);
                return 0;
              }
              //else
              return annualLoadResponse
                .json()
                .then(({ data: { annual_load, operation_days } }) => {
                  //todo: get the backend to do all this extra evAssessment assignment stuff
                  evAssessmentData.load_profile.annual15 = annual_load;
                  evAssessmentData.operation_days = operation_days;
                  return 1;
                });
            })
            .catch((e) => {
              snackBarElement.current.displayToast(
                `Network Error: Failed to perform ${
                  stepInfo[STEP_NUMBER + 1].label
                } Analysis`
              );
              console.log(e);
              setButtonLoading(false);
              return 0;
            });

          const financialVehiclePromise = async () => {
            //removes duplicate vehicle models from battery selected rows, and makes an array of the model strings
            const blockVehicles = bevData[
              selectedRow
            ].fleet.model_breakdown.map((model) => model.model);

            const {
              data: { data: vehicles },
            } = await axios.get(vehicleURL, { headers });

            return vehicles
              .filter((el) => blockVehicles.includes(el.model))
              .map((el) => ({
                ...el,
                maintenanceVehBEV: maintenanceConstants.vehicle.BEV,
                maintenanceVehICE: maintenanceConstants.vehicle.ICE,
                maintenanceVehPROP: maintenanceConstants.vehicle.PROP,
                maintenanceVehCNG: maintenanceConstants.vehicle.CNG,
                maintenanceVehGAS: maintenanceConstants.vehicle.GAS,

                fuel: "ICE",
                subsidiesVeh: 0,
                incentiveVeh: 0,
                batteryRepCost: 0,
                batteryResValue: 0,
                total_ev_fleet_size:
                  evAssessmentData.fleet_size.total_ev_fleet_size,
                total_ice_fleet_size:
                  evAssessmentData.fleet_size.total_ice_fleet_size,
              }));
          };

          const financialChargerPromise = async () => {
            //get the masterdata charger array, for use constructing the financial analysis page's table
            const {
              data: { data: charger },
            } = await axios.get(chargerURL, { headers });
            //Format and store the data for the financial Analysis page's tables
            //TODO: Would be nice if the backend could respond to fleet-sizing with the cost, so we wouldn't need to query the masterdata backend
            return charger
              .filter((el) => el.model === selectedCharger)
              .map((el) => ({
                ...el,
                incCost: 0,
                subsidiesCharger: 0,
                incentiveCharger: 0,
                chargerOM: 10,
                count: bevData[selectedRow].num_chargers,
              }));
          };

          const financialEnergyPromise = async () => {
            const depot_id = submissionData.battery.data[0].startDepot;

            //gets the emission data (just used once in constructing financialEnergy)
            const {
              data: { data: emissions },
            } = await axios.get(emissionsURL + "?depot_id=" + depot_id, {
              headers,
            });

            return [
              {
                infraUpCost: 1000,
                elecCostEnergy: 0.11,
                elecCostDemand: 10,
                gridEmission: emissions[0]?.emission,
                NOXEmission: emissions[0]?.nox,
                SO2Emission: emissions[0]?.so2,
                dieselCost: 3.5,
                tableData: { uuid: uuidv4() },
                ...emissionsConstants,
              },
            ];
          };

          const [
            isAnnualLoadSuccess,
            financialVehicle,
            financialCharger,
            financialEnergy,
          ] = await Promise.all([
            annualLoadPromise,
            financialVehiclePromise(),
            financialChargerPromise(),
            financialEnergyPromise(),
          ]).catch((e) => {
            console.log("Something went wrong", e);
            snackBarElement.current.displayToast(
              "Network Error: Something went wrong",
              "error",
              5000
            );
            setButtonLoading(false);
          });

          if (
            !isAnnualLoadSuccess ||
            !financialVehicle ||
            !financialCharger ||
            !financialEnergy
          )
            return;

          storeLocalData("evAssessment", { data: evAssessmentData });

          storeLocalData("financial", {
            data: {
              vehicle: financialVehicle,
              charger: financialCharger,
              energy: financialEnergy,
            },
          });

          //overwrite the old fleetData data with the current data, which now has the "row" input
          storeLocalData("fleetSizing", {
            data: fleetData,
            input: { row: selectedRow },
          });

          //Save the data on the backend Body
          const backendBody = {
            id: sim.id, //The simulation ID
            current_page: stepInfo[STEP_NUMBER + 1].route,
            steps: {
              input: {
                fleetSizing: { row: selectedRow },
              },
              evAssessment: evAssessmentData,
              financial: {
                vehicle: financialVehicle,
                charger: financialCharger,
                energy: financialEnergy,
              },

              //clear out future pages' backend data
              tco: {},
            },
            completed: false,
          };

          //clear out the future pages' frontend data
          partialClearLocalData(["tco"]);

          fetch(simulationURL, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(backendBody),
          })
            .then((response) => {
              if (!response.ok) {
                errorHandler(
                  response,
                  snackBarElement,
                  "Error Sending Data to Backend"
                );
                setButtonLoading(false);
                return;
              }
              //else
              snackBarElement.current.displayToast(
                `${stepInfo[STEP_NUMBER].label} Analysis Complete`,
                "success",
                1000
              );
              navigate(stepInfo[STEP_NUMBER + 1].route);
            })
            .catch((e) => {
              snackBarElement.current.displayToast(
                "Error Sending Data to Backend",
                "error"
              );
              console.log("error", e);
              setButtonLoading(false);
            });
        });
      })
      .catch((e) => {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to get resource data");
        else {
          //snackbar error for uncaught exception
          snackBarElement.current.displayToast(
            "Something went wrong, try again later",
            "error"
          );
          console.log("unknown error", e);
        }
        setButtonLoading(false);
      });
  };

  /** @returns {import("material-react-table").MRT_TableOptions<never} */
  const sharedTableProps = () => ({
    ...materialReactTableOptions(),
    state: { ...materialReactTableOptions().state, isLoading: !isDataFetched },
    enableToolbarInternalActions: false, //hides the toolbar actions without hiding the custom toolbar chips
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableFilters: false,
    enableSorting: false,
    enablePagination: false, //disabling pagination seems to remove the limit on number of rows displayed at once (tested for up to 14 rows)
    renderTopToolbarCustomActions: () => (
      <div style={{ padding: "0px 10px" }}>
        <Stack direction="row" spacing={1}>
          <Chip
            label="Electric Vehicles (BEV)"
            variant={tableDisplay == "BEV" ? "filled" : "outlined"}
            onClick={() => setTableDisplay("BEV")}
          />
          <Chip
            label="Conventional Vehicles (ICE)"
            variant={tableDisplay == "ICE" ? "filled" : "outlined"}
            onClick={() => setTableDisplay("ICE")}
            sx={{ display: iceData.length ? "" : "none" }} //hides the conventional vehicle chip if that view is empty
          />
        </Stack>
      </div>
    ),
  });

  const BevTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: selectedBevData,
    columns: bevColumns,
    state: {
      ...sharedTableProps().state,
      columnVisibility: { scenario: false },
    },
  });

  const BevScenarioTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: bevData,
    columns: bevColumns,
    state: {
      ...sharedTableProps().state,
      isLoading: undefined, //de-selects the sharedTable's loading state
      columnVisibility: { num_blocks: false, block_count: false },
      rowSelection: rowSelection,
      showAlertBanner: Object.values(rowSelection).some((i) => i), //included for consistency, but not actually shown to user
    },
    //miscellaneous options
    renderEmptyRowsFallback: () => (
      <Typography textAlign="center">
        Failed to Compute Feasible Scenarios
      </Typography>
    ),
    enableTopToolbar: false, //hides the sharedTableProps' chip selection
    //selection options
    enableRowSelection: true,
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: "none", //hides the "n rows selected banner"
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: ({ row, ...rest }) => {
      const props = parseFromValuesOrFunc(
        sharedTableProps().muiTableBodyRowProps,
        { row, ...rest }
      );
      return {
        ...props,
        onClick: row.getIsSelected()
          ? undefined
          : row.getToggleSelectedHandler(), // allows user to click anywhere on row to select (and prevents user from deselecting rows)
        sx: (theme) => ({
          ...parseFromValuesOrFunc(props?.sx, theme),
          cursor: "pointer",
        }),
      };
    },
  });

  const IceTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: iceData,
    columns: iceColumns,
    //miscellaneous options
    renderEmptyRowsFallback: () => (
      <Typography textAlign="center">No scenarios that require ICE</Typography>
    ),
  });

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
        <Chip
          label={chipNameLookup[chipView]}
          onClick={(e) => setChipAnchorEl(e.currentTarget)}
        />
        <Menu
          open={Boolean(chipAnchorEl)}
          anchorEl={chipAnchorEl}
          onClose={() => setChipAnchorEl(null)}
          PaperProps={{
            style: { minWidth: chipAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(chipNameLookup).map(([value, label]) => (
            <MenuItem
              key={`${label} option`}
              value={value}
              onClick={(e) => {
                setChipView(e.currentTarget.value);
                setChipAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          {chipView === 0 ? (
            <MaterialReactTable
              table={tableDisplay == "BEV" ? BevTable : IceTable}
            />
          ) : chipView === 1 ? (
            <FleetSizingGraph data={bevData[selectedRow]} />
          ) : (
            <FleetSizingTimeTable
              data={
                submissionData?.battery?.data?.map((row) => ({
                  ...row,
                  //profile is a single day's values looped 5 times
                  profile: new Array(5).fill(1).map((_val, index) => ({
                    start_time: row.dh_st_time + 1440 * index,
                    end_time: row.dh_end_time + 1440 * index,
                    distance: row.distance,
                  })),
                })) || []
              }
              block_fleet_growth_diff={
                bevData[selectedRow]?.block_fleet_growth_diff
              }
            />
          )}
        </Paper>
      </Container>
      <br /> <br />
      <Container fixed>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={stepInfo[STEP_NUMBER - 1].route}
                startIcon={<ArrowBackIosNew />}
              >
                Previous Step: {stepInfo[STEP_NUMBER - 1].label}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                onClick={() => setShowScenarioDialog(true)}
              >
                Review/Change Charging Scenarios
              </Button>
            </Grid>
          </Grid>
          <span>
            <NextPageButton
              sx={{ width: "98%" }}
              onClick={handleEvAssessment}
              loading={buttonLoading}
              disabled={
                !bevData[selectedRow] ||
                !accessRights.analysis.create_depot_energy_analysis ||
                !submissionData ||
                !submissionData?.battery?.data?.length
              }
            >
              Run {stepInfo[STEP_NUMBER + 1].label} Analysis
            </NextPageButton>
          </span>
        </Stack>
      </Container>
      <Dialog
        open={showScenarioDialog}
        onClose={() => setShowScenarioDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Select Charging Scenario</DialogTitle>
        <DialogContent>
          <MaterialReactTable table={BevScenarioTable} />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            className="btn"
            sx={{ mb: 2, marginRight: "5%" }}
            onClick={() => setShowScenarioDialog(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* The loading screen that appears for 3 seconds after the data has been sent to backend */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={buttonLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}

export default FleetSizing;

import {
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import { getLabel } from "../../utils";

export default function FeasibleSubmissionDialog({ initialFeasibleChoice }) {
  const [feasibleChoice, setFeasibleChoice] = useState(initialFeasibleChoice);
  return (
    <DialogContent>
      <FormControl>
        <FormLabel sx={{ color: "black", fontSize: 17 }}>
          You have selected {getLabel("blocks").toLowerCase()} which cannot be
          completed with desired battery size. <br /> Please select a strategy
          for these Infeasible {getLabel("blocks").toLowerCase()}:
        </FormLabel>
        <br />
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={feasibleChoice}
          onChange={(e) => setFeasibleChoice(+e.target.value)}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={`Assume a larger battery will be available and include the ${getLabel(
              "blocks"
            ).toLowerCase()}`}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={`Remove these ${getLabel(
              "blocks"
            ).toLowerCase()} from the Electrification Plan and Maintain ICE operation for them`}
          />
        </RadioGroup>
      </FormControl>
    </DialogContent>
  );
}

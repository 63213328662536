import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  chargerURL,
  emissionsURL,
  vehicleURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { errorHandler } from "../utils";

/** props applied to all textfield inputs in dialog boxes */
export const dialogTextFieldProps = { size: "small", required: true };

/** props applied to all textfield DROPDOWN/SELECT inputs in dialog boxes */
export const dialogTextFieldSelectProps = {
  ...dialogTextFieldProps,
  variant: "outlined",
  fullWidth: true,
  select: true,
  SelectProps: { MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } } },
};

/** props applied to all grid items in dialog boxes*/
export const dialogGridItemProps = { item: true, xs: 6, md: 3 };

/** props applied to all grid item labels in dialog boxes */
export const dialogGridLabelProps = {
  ...dialogGridItemProps,
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
};

/**
 * the edit/add masterData model dialog boxes
 * @param {Object} props
 * @param {{dialogView: Number, modelState: Object[]}} props.state if dialogView === 1, open vehicle model dialog, === 2 for charger model, and ===3 for emission, modelState contains the state of the new/edit model's default values
 * @param {function} props.setState
 * @param {Object[]} props.allData contains all the models in the table of the selected edit state
 * @param {Function} props.setAllData
 */
export default function MasterDataDialogs({
  state,
  setState,
  allData,
  setAllData,
}) {
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState(""); /// this is used, instead of a standard const, to prevent the display name from temporarily dissapeearing while the dialog box is fading out

  const schoolbusSizes = ["A", "B", "C", "D"];

  const { snackBarElement } = useContext(SnackBarContext);

  const ModelState = state?.modelState;
  /** determines if the user is in Edit mode (true) or add mode (false) */
  const isEditMode = Boolean(ModelState?.id || ModelState?.model); //id is for emissions, once combined; model is for vehicles/chargers

  useEffect(() => {
    if (state?.dialogView !== 0) {
      switch (state?.dialogView) {
        case 1:
          setDisplayName("Vehicle");
          break;
        case 2:
          setDisplayName("Charger");
          break;
        case 3:
          setDisplayName("Emission");
          break;
        default:
          break;
      }
    }
  }, [state?.dialogView]);

  //example vehicle model, used as placeholder in create vehicle model dialog
  const placeholderVehicle = {
    model: "VM1",
    type: 1,
    size: 40,
    battery_capacity: 440,
    min_soc: 10,
    max_soc: 95,
    max_c_rate_cp: 300,
    max_c_rate_cv: 30,
    cp_to_cv: 80,
    efficiency: 2.5,
    charging_efficiency: 0.9,
    cdl: 0.6,
    crr: 0.08,
    curb_weight: 29849,
    wheel_radius: 0.47,
    vehicle_length: 40.0,
    vehicle_height: 10.0,
    vehicle_width: 10.0,
    number_of_doors: 1,
    door_height: 6.3,
    door_width: 3.3,
    passenger_capacity: 40,
    payload_capacity: 10000,
    vent_power: 0.5,
    aux_power: 2.0,
    ice_veh_cost: 100000,
    bev_veh_cost: 200000,
    prop_veh_cost: 0.0,
    cng_veh_cost: 0.0,
    gas_veh_cost: 0.0,
    ice_veh_mpg: 1.0,
    prop_veh_mpg: 1.0,
    cng_veh_mpg: 1.0,
    gas_veh_mpg: 1.0,
  };

  //example charger model, used in create new charger model dialog
  const placeholderCharger = {
    model: "M2",
    electricity_type: 1,
    charging_type: 1,
    rating: 19.2,
    number_of_ports: 1,
    efficiency: 0.98,
    cost: 5000,
  };

  /** handles any final data conversions that are necessary, but cannot be handled natively with pure html */
  function dataConversion(dataJSON) {
    if (state?.dialogView === 2)
      dataJSON.efficiency = dataJSON.efficiency / 100;
  }

  /**
   * closes the "create a new vehicle model" dialog box
   */
  const handleClose = () => {
    setState({ ...state, dialogView: 0 });
    setLoading(false);
  };

  /**
   * creates a new vehicle model, and closes the dialog box
   * @param {SubmitEvent} event
   */
  const handleCreate = (event) => {
    event.preventDefault();
    setLoading(true);
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    if (!UseAuth("get")) {
      window.location.assign("/login"); ///TODO: should display error page
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    dataConversion(newDataJSON);

    const url =
      state?.dialogView === 1
        ? vehicleURL
        : state?.dialogView === 2
        ? chargerURL
        : state?.dialogView === 3 // technically, will never occur
        ? emissionsURL
        : undefined;

    fetch(url, { method: "POST", headers, body: JSON.stringify(newDataJSON) })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(`${displayName} Model Created`);
          setAllData([...allData, newDataJSON]);
          handleClose();
        } else {
          errorHandler(
            response,
            snackBarElement,
            `Error Creating ${displayName} Model`
          );
        }
      })
      .catch((error) => {
        console.log("newVehicle", newDataJSON);
        snackBarElement?.current?.displayToast(
          `Error Creating ${displayName} Model`,
          "error"
        );
        console.log("error", error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Updates a charger row, and sends the updated object to backend in PATCH request
   * @param {SubmitEvent} event
   */
  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateDataForm = new FormData(event.target);
    const updateDataJSON = Object.fromEntries(updateDataForm.entries());
    dataConversion(updateDataJSON);

    const originalData = allData.find(
      (row) => row?.id == ModelState?.id || row?.model == ModelState?.model
    );

    if (
      Object.entries(updateDataJSON).every(
        ([key, value]) => String(originalData?.[key]) == value
      )
    ) {
      //if data is unaltered, then display an info card, and return
      snackBarElement.current.displayToast(
        "Data was not altered from Original",
        "info"
      );
      setLoading(false);
      return;
    }

    if (!UseAuth("get")) {
      window.location.assign("/login"); ///TODO: should display error page
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const url =
      state?.dialogView === 1
        ? vehicleURL
        : state?.dialogView === 2
        ? chargerURL
        : state?.dialogView === 3
        ? emissionsURL
        : undefined;

    fetch(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(updateDataJSON),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(`${displayName} Updated!`);
          const index = allData.findIndex(
            (data) => data.model === updateDataJSON.model
          );
          allData[index] = { id: ModelState?.id, ...updateDataJSON };
          setAllData([...allData]);
          handleClose();
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to update ${displayName} model`,
            "error"
          );
      })
      .catch((err) => {
        snackBarElement?.current?.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* Vehicle Model Edit/Add Dialog */}
      <Dialog
        open={state?.dialogView === 1}
        onClose={handleClose}
        onSubmit={isEditMode ? handleUpdate : handleCreate}
        maxWidth="md"
        component="form"
      >
        <DialogTitle>
          {isEditMode ? "Edit" : "Create New"} Vehicle Model
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" spacing={1.5} mt={2}>
            <Grid {...dialogGridLabelProps}>Model</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                autoFocus={!isEditMode}
                id="model"
                name="model"
                type="text"
                inputProps={{ readOnly: isEditMode }}
                // different variants to denote that the model field cannot be editted, without disabling the field (which would prevent it from being included in form submission)
                variant={isEditMode ? "filled" : "standard"}
                hiddenLabel //hide label to help the filled variant look more in-line with rest of form
                defaultValue={ModelState?.model ?? ""}
                placeholder={placeholderVehicle.model}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Type</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldSelectProps}
                autoFocus={isEditMode}
                id="type"
                name="type"
                value={ModelState?.type || ""}
                label="Vehicle Type"
                onChange={(event) => {
                  //if changing to/form schoolbus type, clear out the size input
                  if (ModelState?.type == 4 || event.target.value == 4)
                    setState({
                      ...state,
                      modelState: {
                        ...ModelState,
                        type: event.target.value,
                        size: "",
                      },
                    });
                  else
                    setState({
                      ...state,
                      modelState: {
                        ...ModelState,
                        type: event.target.value,
                      },
                    });
                }}
              >
                {Object.entries(TYPE_STRINGS.VEHICLE_TYPE).map(
                  ([key, vehicle_type]) => (
                    //populates menu with vehicle types, based on types from utils.js
                    <MenuItem key={vehicle_type} value={key}>
                      {vehicle_type}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid {...dialogGridLabelProps}>
              {ModelState?.type == 1
                ? "Size"
                : ModelState?.type == 4
                ? "School Bus Type"
                : "Class"}
            </Grid>
            <Grid {...dialogGridItemProps}>
              {ModelState?.type == 4 ? (
                <TextField
                  {...dialogTextFieldSelectProps}
                  id="size"
                  name="size"
                  value={ModelState?.size || ""}
                  label="A/B/C/D"
                  onChange={(event) =>
                    setState({
                      ...state,
                      modelState: {
                        ...ModelState,
                        size: event.target.value,
                      },
                    })
                  }
                >
                  {schoolbusSizes.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  {...dialogTextFieldProps}
                  id="size"
                  name="size"
                  value={ModelState?.size || ""}
                  placeholder={`${placeholderVehicle.size}`}
                  onChange={(event) =>
                    setState({
                      ...state,
                      modelState: {
                        ...ModelState,
                        size: event.target.value,
                      },
                    })
                  }
                  type={ModelState?.type == 1 ? "number" : undefined}
                  InputProps={
                    ModelState?.type == 1
                      ? {
                          inputProps: { min: 1 },
                          endAdornment: (
                            <InputAdornment position="end">
                              {ModelState?.type == 1 && "ft"}
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
              )}
            </Grid>
            <Grid {...dialogGridLabelProps}>Default Efficiency</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="efficiency"
                name="efficiency"
                type="number"
                defaultValue={ModelState?.efficiency ?? ""}
                placeholder={`${placeholderVehicle.efficiency}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">kWh/mi</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Vehicle Length</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="vehicle_length"
                name="vehicle_length"
                type="number"
                defaultValue={ModelState?.vehicle_length ?? ""}
                placeholder={`${placeholderVehicle.vehicle_length}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Number of Doors</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="number_of_doors"
                name="number_of_doors"
                type="number"
                defaultValue={ModelState?.number_of_doors ?? ""}
                placeholder={`${placeholderVehicle.number_of_doors}`}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Vehicle Height</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="vehicle_height"
                name="vehicle_height"
                type="number"
                defaultValue={ModelState?.vehicle_height ?? ""}
                placeholder={`${placeholderVehicle.vehicle_height}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Door Width</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="door_width"
                name="door_width"
                type="number"
                defaultValue={ModelState?.door_width ?? ""}
                placeholder={`${placeholderVehicle.door_width}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Vehicle Width</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="vehicle_width"
                name="vehicle_width"
                type="number"
                defaultValue={ModelState?.vehicle_width ?? ""}
                placeholder={`${placeholderVehicle.vehicle_width}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Door Height</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="door_height"
                name="door_height"
                type="number"
                defaultValue={ModelState?.door_height ?? ""}
                placeholder={`${placeholderVehicle.door_height}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>Battery Capacity</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="battery_capacity"
                name="battery_capacity"
                type="number"
                defaultValue={ModelState?.battery_capacity ?? ""}
                placeholder={`${placeholderVehicle.battery_capacity}`}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Max. Charge Rate at Constant Power
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="max_c_rate_cp"
                name="max_c_rate_cp"
                type="number"
                defaultValue={ModelState?.max_c_rate_cp ?? ""}
                placeholder={`${placeholderVehicle.max_c_rate_cp}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">kW</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Min. State of Charge</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="min_soc"
                name="min_soc"
                type="number"
                defaultValue={ModelState?.min_soc ?? ""}
                placeholder={`${placeholderVehicle.min_soc}`}
                InputProps={{
                  inputProps: { min: 0, max: 100, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Max. Charge Rate at Constant Voltage
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="max_c_rate_cv"
                name="max_c_rate_cv"
                type="number"
                defaultValue={ModelState?.max_c_rate_cv ?? ""}
                placeholder={`${placeholderVehicle.max_c_rate_cv}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">kW</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Max. State of Charge</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="max_soc"
                name="max_soc"
                type="number"
                defaultValue={ModelState?.max_soc ?? ""}
                placeholder={`${placeholderVehicle.max_soc}`}
                InputProps={{
                  inputProps: { min: 1, max: 100, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Constant Power to Constant Voltage
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="cp_to_cv"
                name="cp_to_cv"
                type="number"
                defaultValue={ModelState?.cp_to_cv ?? ""}
                placeholder={`${placeholderVehicle.cp_to_cv}`}
                InputProps={{
                  inputProps: { min: 0, max: 100, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid {...dialogGridLabelProps}>Charging Efficiency</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="charging_efficiency"
                name="charging_efficiency"
                type="number"
                defaultValue={ModelState?.charging_efficiency ?? ""}
                placeholder={`${placeholderVehicle.charging_efficiency}`}
                InputProps={{
                  inputProps: { min: 0, max: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>Auxiliary Power</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="aux_power"
                name="aux_power"
                type="number"
                defaultValue={ModelState?.aux_power ?? ""}
                placeholder={`${placeholderVehicle.aux_power}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">kW </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Vent Power</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="vent_power"
                name="vent_power"
                type="number"
                defaultValue={ModelState?.vent_power ?? ""}
                placeholder={`${placeholderVehicle.vent_power}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">kW </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>Curb Weight</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="curb_weight"
                name="curb_weight"
                type="number"
                defaultValue={ModelState?.curb_weight ?? ""}
                placeholder={`${placeholderVehicle.curb_weight}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">Lb</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Passenger Capacity</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="passenger_capacity"
                name="passenger_capacity"
                type="number"
                defaultValue={ModelState?.passenger_capacity ?? ""}
                placeholder={`${placeholderVehicle.passenger_capacity}`}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Payload Capacity</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="payload_capacity"
                name="payload_capacity"
                type="number"
                defaultValue={ModelState?.payload_capacity ?? ""}
                placeholder={`${placeholderVehicle.payload_capacity}`}
                InputProps={{
                  inputProps: { min: 0 },
                  //should be Lb, but data is currently in kg
                  endAdornment: (
                    <InputAdornment position="end">Lb</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>BEV Cost</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="bev_veh_cost"
                name="bev_veh_cost"
                type="number"
                defaultValue={ModelState?.bev_veh_cost ?? ""}
                placeholder={`${placeholderVehicle.bev_veh_cost}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Internal Combustion
              <br />
              Engine Cost
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="ice_veh_cost"
                name="ice_veh_cost"
                type="number"
                defaultValue={ModelState?.ice_veh_cost ?? ""}
                placeholder={`${placeholderVehicle.ice_veh_cost}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Propane Cost</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="prop_veh_cost"
                name="prop_veh_cost"
                type="number"
                defaultValue={ModelState?.prop_veh_cost ?? ""}
                placeholder={`${placeholderVehicle.prop_veh_cost}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Compressed Natural
              <br />
              Gas Cost
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="cng_veh_cost"
                name="cng_veh_cost"
                type="number"
                defaultValue={ModelState?.cng_veh_cost ?? ""}
                placeholder={`${placeholderVehicle.cng_veh_cost}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Gasoline Cost</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="gas_veh_cost"
                name="gas_veh_cost"
                type="number"
                defaultValue={ModelState?.gas_veh_cost ?? ""}
                placeholder={`${placeholderVehicle.gas_veh_cost}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>Internal Combustion Engine</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="ice_veh_mpg"
                name="ice_veh_mpg"
                type="number"
                defaultValue={ModelState?.ice_veh_mpg ?? ""}
                placeholder={`${placeholderVehicle.ice_veh_mpg}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">mpg</InputAdornment>
                  ),
                }}
              />
            </Grid>{" "}
            <Grid {...dialogGridLabelProps}>Propane</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="prop_veh_mpg"
                name="prop_veh_mpg"
                type="number"
                defaultValue={ModelState?.prop_veh_mpg ?? ""}
                placeholder={`${placeholderVehicle.prop_veh_mpg}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">mpg</InputAdornment>
                  ),
                }}
              />
            </Grid>{" "}
            <Grid {...dialogGridLabelProps}>Compressed Natural Gas</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="cng_veh_mpg"
                name="cng_veh_mpg"
                type="number"
                defaultValue={ModelState?.cng_veh_mpg ?? ""}
                placeholder={`${placeholderVehicle.cng_veh_mpg}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">mpg</InputAdornment>
                  ),
                }}
              />
            </Grid>{" "}
            <Grid {...dialogGridLabelProps}>Gasoline</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="gas_veh_mpg"
                name="gas_veh_mpg"
                type="number"
                defaultValue={ModelState?.gas_veh_mpg ?? ""}
                placeholder={`${placeholderVehicle.gas_veh_mpg}`}
                InputProps={{
                  inputProps: { min: 1, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">mpg</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid {...dialogGridLabelProps}>Coefficient of Drag</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="cdl"
                name="cdl"
                type="number"
                defaultValue={ModelState?.cdl ?? ""}
                placeholder={`${placeholderVehicle.cdl}`}
                InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>
              Coefficient of Rolling Restistance
            </Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="crr"
                name="crr"
                type="number"
                defaultValue={ModelState?.crr ?? ""}
                placeholder={`${placeholderVehicle.crr}`}
                InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Wheel Radius</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="wheel_radius"
                name="wheel_radius"
                type="number"
                defaultValue={ModelState?.wheel_radius ?? ""}
                placeholder={`${placeholderVehicle.wheel_radius}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={6} md={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                className="btn"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <LoadingButton
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                className="btn"
                loading={loading}
              >
                {isEditMode ? "Update" : "Create"} Vehicle Model
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Charger Model Edit/Add Dialog */}

      <Dialog
        open={state?.dialogView === 2}
        onClose={handleClose}
        onSubmit={isEditMode ? handleUpdate : handleCreate}
        maxWidth="md"
        component="form"
      >
        <DialogTitle>
          {isEditMode ? "Edit" : "Create New"} Charger Model
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" spacing={2} mt={2}>
            <Grid {...dialogGridLabelProps}>Model</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                autoFocus={!isEditMode}
                id="model"
                name="model"
                type="text"
                inputProps={{ readOnly: isEditMode }}
                // different variants to denote that the model field cannot be editted, without disabling the field (which would prevent it from being included in form submission)
                variant={isEditMode ? "filled" : "standard"}
                hiddenLabel //hide label to help the filled variant look more in-line with rest of form
                defaultValue={ModelState?.model ?? ""}
                placeholder={placeholderCharger.model}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Electricity Type</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldSelectProps}
                autoFocus={isEditMode}
                id="electricity_type"
                name="electricity_type"
                defaultValue={ModelState?.electricity_type ?? ""}
                label="AC/DC"
              >
                <MenuItem key={1} value={1}>
                  AC
                </MenuItem>
                <MenuItem key={2} value={2}>
                  DC
                </MenuItem>
              </TextField>
            </Grid>
            <Grid {...dialogGridLabelProps}>Charging Type</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldSelectProps}
                id="charging_type"
                name="charging_type"
                defaultValue={ModelState?.charging_type ?? ""}
                label="Sequential/Parallel"
              >
                <MenuItem key={1} value={1}>
                  Sequential
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Parallel
                </MenuItem>
              </TextField>
            </Grid>
            <Grid {...dialogGridLabelProps}>Rating</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="rating"
                name="rating"
                type="number"
                defaultValue={ModelState?.rating ?? ""}
                placeholder={`${placeholderCharger.rating}`}
                InputProps={{
                  inputProps: { min: 0, step: 0.1 },
                  endAdornment: (
                    <InputAdornment position="end">kW</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Port Count</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="number_of_ports"
                name="number_of_ports"
                type="number"
                defaultValue={ModelState?.number_of_ports ?? ""}
                placeholder={`${placeholderCharger.number_of_ports}`}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Efficiency</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="efficiency"
                name="efficiency"
                type="number"
                defaultValue={ModelState?.efficiency * 100 ?? ""}
                placeholder={`${placeholderCharger.efficiency * 100}`}
                InputProps={{
                  inputProps: { min: 0, max: 100, step: 1 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid {...dialogGridLabelProps}>Cost</Grid>
            <Grid {...dialogGridItemProps}>
              <TextField
                {...dialogTextFieldProps}
                id="cost"
                name="cost"
                type="number"
                defaultValue={ModelState?.cost ?? ""}
                placeholder={`${placeholderCharger.cost}`}
                InputProps={{
                  inputProps: { min: 0.0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            className="btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            className="btn"
            loading={loading}
          >
            {isEditMode ? "Update" : "Create"} Charger Model
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

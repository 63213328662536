import Add from "@mui/icons-material/Add";
import CallSplit from "@mui/icons-material/CallSplit";
import MoveUp from "@mui/icons-material/MoveUp";
import Remove from "@mui/icons-material/Remove";
import { duration } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import { useContext, useEffect, useRef, useState } from "react";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { nestableListTourOptions } from "../../../static/constants/tourOptions";
import TourBeacon from "../../secondary/tourBeacon";
import { getLabel, Military_to_AMPM, Military_to_MFM } from "../../utils";

const rowsPerPage = 10;

export default function NestableList({
  items,
  setData,
  setDialogOpen,
  rowSelection,
  handleSelectionChange,
}) {
  //Todo: maybe expand this typedef and put it somewhere else more accessable to other components
  /** row type for children
   * @typedef {Object} Row
   * @property {Number} dh_st_time
   * @property {Number} dh_end_time
   * @property {String} BlockId
   * @property {String} [block_id_original]
   */
  /**
   *
   * @param {Row[]} input - the input rows from routeDefinition
   * @param {String} input[].block_id_original - used to determine the default group a row falls into
   * @param {String} input[].blockId - if no block_id_original is specified, each row is given its own default group
   * @param {import("material-react-table").MRT_RowSelectionState} rowSelection
   * @returns {{groupId: {children: Row[], isCollapsed: Boolean}}}
   */
  const initialDataStateHelper = (input, rowSelection) => {
    let data = {};
    //create base layout
    input.forEach((row) => {
      row.checked = rowSelection[row.id];
      if (row.block_id_original) {
        if (data[row.block_id_original]) {
          //if data found at key, add it to the default combo
          data[row.block_id_original].children.push(row);
        } else {
          // otherwise create a new default combo for that list
          data[row.block_id_original] = {};
          data[row.block_id_original].isCollapsed = true;
          data[row.block_id_original].children = [row];
        }
      } else {
        //otherwise, just use the unique block ID for the combo key
        data[row.blockId] = {};
        data[row.blockId].isCollapsed = true;
        data[row.blockId].children = [row];
      }
    });
    return data;
  };

  const { snackBarElement } = useContext(SnackBarContext);
  const [page, setPage] = useState(1); //used for pagination
  const [dataMap, setDataMap] = useState({});
  const [filters, setFilters] = useState([]);
  const dragItem = useRef();

  //tour variables
  const [isTourActive, setIsTourActive] = useState(false);

  //initialization (in a useEffect instead of useState, as it needs to re-set the dataMap once the items are fetched from indexDb by parent component)
  useEffect(() => {
    setDataMap(initialDataStateHelper(items, rowSelection));
  }, [items, rowSelection]);

  /** closes the dialog box  */
  const handleCloseDialog = (e) => {
    setDialogOpen(false);
  };

  /**
   * Saves the new groups and closes the dialog box
   * Also, checks that no group has overlapping start/end times, and cancels submit if one does
   * @param {SubmitEvent} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    /**
     * checks that there are no overlapping start/end times
     * @param {Object} block the block being checked
     * @param {Number} index the index of the block being checked
     * @param {Object[]} arr a copy of the array being read, **assume pre-sorted**
     * @returns {Boolean}
     */
    const isNotOverlapping = (block, index, arr) =>
      index === arr.length - 1 ||
      block.dh_end_time <= arr[index + 1].dh_st_time;

    //finds first occurrance of overlapping times, if any. If none, returns undefined
    const overlapTimeCheck = Object.entries(dataMap).find(([_, value]) => {
      //copy to prevent modification of original dataMap by sort
      const shallowChildrenCopy = [...value.children];
      // sorts rows by start time
      shallowChildrenCopy.sort(
        (a, b) => Military_to_MFM(a.dh_st_time) - Military_to_MFM(b.dh_st_time)
      );
      return !shallowChildrenCopy.every(isNotOverlapping);
    });

    if (overlapTimeCheck) {
      snackBarElement.current.displayToast(
        `Group ${overlapTimeCheck[0]} contains overlapping time ranges, cannot continue`,
        "error",
        5000
      );
      return;
    }

    /**
     * the dataMap, converted back into the original data form
     */
    const newData = Object.entries(dataMap).reduce(
      (prev, [block_id_original, { children }]) => [
        ...prev,
        ...children.map((row) => ({ ...row, block_id_original })),
      ],
      []
    );

    handleSelectionChange(
      newData.reduce((newRowSelection, row) => {
        const output = {
          ...newRowSelection,
          [row.id]: Boolean(row.checked),
        };
        delete row.checked;
        return output;
      }, {})
    );
    setData(newData);
    handleCloseDialog();
  };

  /**
   * Expands all dropdowns in nestable list
   * @param {MouseEvent} e
   */
  const expandAll = (e) => {
    e.preventDefault();
    Object.keys(dataMap).forEach((key) => (dataMap[key].isCollapsed = false));
    setDataMap({ ...dataMap });
  };

  /** collapses/expands a single row in nestable list based on groupId
   * @param {[MouseEvent]} e
   * @param {String} groupId the group to expand/collapse
   */
  const toggleCollapse = (e, groupId) => {
    e?.preventDefault();
    dataMap[groupId].isCollapsed = !dataMap[groupId].isCollapsed;
    setDataMap({ ...dataMap }); //causes a re-render
  };

  /**
   * Collapses all rows
   * @param {MouseEvent} e
   */
  const collapseAll = (e) => {
    e?.preventDefault();
    Object.keys(dataMap).forEach((key) => (dataMap[key].isCollapsed = true));
    setDataMap({ ...dataMap }); //causes a re-render
  };

  /**
   * assigns a helper function to the dragItem ref that, when fired, removes and returns the dragged row from the datamap
   * @param {DragEvent} e
   * @param {String} groupId the parent group that contains the selected item
   * @param {Number} itemIndex the index of the row in the parent group
   */
  const dragStart = (e, groupId, itemIndex) => {
    /**
     * removes the row from DataMap
     * @param {{groupId: {children: Row[], isCollapsed: Boolean}}} allData dataMap
     * @returns {Row} row removed from dataMap
     */
    dragItem.current = (allData) =>
      allData[groupId].children.splice(itemIndex, 1)[0];
  };

  /**
   * moves row from one group to another group
   * @param {DragEvent} e
   * @param {String} groupId destination group
   */
  const dragDrop = (e, groupId) => {
    e.preventDefault();
    const movedItem = dragItem.current(dataMap);

    if (
      !dataMap[groupId]?.children?.every(
        (child) =>
          child.dh_end_time <= movedItem.dh_st_time ||
          child.dh_st_time >= movedItem.dh_end_time
      )
    ) {
      snackBarElement.current.displayToast("Time Range Overlap", "warning");
    }

    dataMap[groupId].children.push(movedItem);
    setDataMap({ ...dataMap }); //causes a re-render
  };

  /**
   * Splits each row in a group into their own row, based off BlockId
   * @param {MouseEvent} e
   * @param {Row[]} children the rows to be split
   */
  const splitRow = (e, children) => {
    e.preventDefault();
    while (children.length > 1) {
      const child = children.pop();
      let newGroupKey = child.blockId;
      while (dataMap[newGroupKey]) newGroupKey += "_dup"; // if a group with the new key name already exists in the dataMap, keep adding _dup to the key name until one is found that doesn't exist
      dataMap[newGroupKey] = { isCollapsed: true, children: [child] };
    }
    setDataMap({ ...dataMap });
  };

  /**
   * renders a child row (the rows that appear in dropdowns)
   * @param {Row} row - the row to be rendered
   * @param {String} groupId the key of the parent containing the row in the dataMap
   * @param {Number} index the index of the row in the children array of the value associated with the Datamap's groupId
   */
  const renderChild = (row, groupId, index) => (
    <ListItem
      sx={{ pl: 10, cursor: "pointer" }}
      draggable
      key={row.blockId}
      onDrag={(e) => dragStart(e, groupId, index)}
    >
      <Tooltip title={`Drag ${getLabel("blocks")} to Move`}>
        <MoveUp />
      </Tooltip>
      &nbsp;&nbsp;{row.blockId}&nbsp;&nbsp;&nbsp;&nbsp;Start:{" "}
      {Military_to_AMPM(row.dh_st_time)}
      &nbsp; &nbsp; End: {Military_to_AMPM(row.dh_end_time)}
    </ListItem>
  );

  /** @typedef {[String, {isCollapsed: Boolean, children: Row[]}]} DataMapEntry */
  /** renders the group's header row
   * @param {DataMapEntry} param0
   * @returns
   */
  const renderParent = ([groupId, { isCollapsed, children }]) => {
    //calculates the max/min times of the parent, for display purposes
    const [max, min] = children.reduce(
      ([max, min], row) => {
        if (max < row.dh_end_time) max = row.dh_end_time;
        if (min > row.dh_st_time) min = row.dh_st_time;
        return [max, min];
      },
      [children[0]?.dh_end_time, children[0]?.dh_st_time]
    );
    return (
      <span
        key={groupId}
        onDragOver={(e) => e.preventDefault()} // allows the onDrop to work
        // onDragEnd //not used, as there is a delay before dragEnd is fired
        onDrop={(e) => dragDrop(e, groupId)}
      >
        <ListItem
          disablePadding
          secondaryAction={
            <Tooltip title={`Separate ${getLabel("blocks")}`}>
              <IconButton
                disabled={children?.length <= 1} // can't split a row with 1 or zero items in it
                edge="end"
                aria-label="split"
                onClick={(e) => splitRow(e, children)}
              >
                <CallSplit
                  fontSize="small"
                  sx={{ transform: "rotate(90deg)" }}
                />
              </IconButton>
            </Tooltip>
          }
        >
          <ListItemButton onClick={(e) => toggleCollapse(e, groupId)}>
            <ListItemIcon>
              {isCollapsed ? (
                <Add fontSize="small" />
              ) : (
                <Remove fontSize="small" />
              )}
            </ListItemIcon>
            <Grid container>
              <Grid item xs={4}>
                {groupId}
              </Grid>
              <Grid item xs={4}>
                Start: {min === undefined ? "N/A" : Military_to_AMPM(min)}{" "}
              </Grid>
              <Grid item xs={4}>
                End: {max === undefined ? "N/A" : Military_to_AMPM(max)}{" "}
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
        <Collapse in={!isCollapsed} unmountOnExit>
          <List component="div" dense disablePadding>
            {children.map((row, index) => renderChild(row, groupId, index))}
          </List>
        </Collapse>
        <Divider />
      </span>
    );
  };

  /** allows users to search by both group name (option) and associated blockIds */
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option}${dataMap[option].children.map(
        (child) => ` ${child.blockId}`
      )}`,
  });

  const SearchComponent = (
    <Autocomplete
      id="filter-combine-runs"
      multiple // allows for more than one option to be selected at a time
      filterSelectedOptions // hides the selected options from the dropdown
      value={filters}
      onChange={(e, newFilters) => setFilters(newFilters)}
      options={Object.keys(dataMap)}
      renderOption={(props, option, _) => (
        <li {...props}>
          Group&nbsp;{option}:&nbsp;
          <Typography noWrap>
            {dataMap[option].children.map((val, i) => [
              i > 0 && ", ", //creates a comma-separated list (without prepending a commat to first element)
              val.blockId,
            ])}
          </Typography>
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Filters" />}
      filterOptions={filterOptions}
    />
  );

  const filteredEntries = Object.entries(dataMap).filter(
    ([key, _]) => !filters.length || filters.includes(key)
  );

  const handleTourStart = () => {
    //collapse all when starting a tour
    collapseAll(undefined);
    //but uncollapse the first group; this is done for styling reasons
    toggleCollapse(undefined, filteredEntries[(page - 1) * rowsPerPage][0]);

    //use timeout to wait for animation to finish
    setTimeout(() => {
      //hide the tour beacon
      setIsTourActive(true);
      //begin the tour
      introJs()
        .setOptions(nestableListTourOptions())
        //note: the following onchange can be used to fire changes between pages
        // .onchange((target) => {
        //   console.log(
        //     target.attributes.getNamedItem("data-testid")?.value
        //   );
        //   if (
        //     target.attributes.getNamedItem("data-testid")?.value ==
        //     "CallSplitIcon"
        //   ) {
        //     toggleCollapse(
        //       undefined,
        //       filteredEntries[(page - 1) * rowsPerPage + 1][0]
        //     );
        //   }
        // })
        .onexit(() => {
          setIsTourActive(false);
        })
        .start();
    }, [duration.standard]);
  };

  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Link {getLabel("blocks")}
          <TourBeacon hidden={isTourActive} onClick={handleTourStart} />
        </Box>
        <DialogContentText>
          Expand groups and drag {getLabel("blocks").toLowerCase()} to link them
          together.
          <br />
          (For example a morning and afternoon {getLabel(
            "block"
          ).toLowerCase()}{" "}
          of the same route)
        </DialogContentText>
        {SearchComponent}
      </DialogTitle>
      <DialogContent>
        <List dense>
          {filteredEntries
            .slice((page - 1) * rowsPerPage, page * rowsPerPage) //for pagination
            .map(renderParent)}
        </List>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <span id="expand-collapse-all-buttons">
          <Button onClick={expandAll}>Expand All</Button>
          <Button onClick={collapseAll}>Collapse All</Button>
        </span>
        <Pagination
          count={Math.ceil(filteredEntries.length / rowsPerPage)}
          onChange={(e, pageNum) => setPage(pageNum)}
        />
        <span id="cancel-submit-buttons">
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} type="submit">
            Save and Continue
          </Button>
        </span>
      </DialogActions>
    </>
  );
}
